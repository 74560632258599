import React, { useEffect, useState } from 'react';
import { IsMobileScreen } from '../../../Constants/Constants';
import { ReactComponent as LeftArrow } from '../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { InputField } from '../../Login/Components/Components';
import PhoneInputDropdown from '../../../Components/MobileNumberInputFlagDropDown/Dropdown';
import { ErrorMessage } from '../../../Components/Error/ErrorMessage';
import { countryCodes } from '../../../Components/MobileNumberInputFlagDropDown/CountryData';
import APIV5 from '../../../api/axios/APIV5';

export const ValidateUserPhoneNumber = (props) => {
    const {
        mobileNumber,
        setIsValidInput,
        otp,
        isValidInput,
        countryData,
        setCountryData,
        methods,
        setIsShowMobileNumberPopup,
    } = props;
    const {
        setValue,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
    } = methods;
    const oldCountryCode = getValues('country_code') ?? '+' + countryData.code;
    const [isShowInputView, setIsShowInputView] = useState(false);
    const [seconds, setSeconds] = useState(30);

    const isValidMobileNumber = mobileNumber?.length === 10;
    const isValidOtp = otp?.length === 5;

    const getOtp = async ({ mobileNumber, countryCode, setError }) => {
        try {
            const response = await APIV5.post(`/restaurants/32/send-otp`, {
                mobile_number: mobileNumber,
                country_code: countryCode,
            });
            if (response.success) {
                clearErrors('mobile_number');
            }
            return response;
        } catch (error) {
            setError('mobile_number', {
                message: error?.response?.data?.errors?.country_code ?? error?.response?.data?.message ?? error.message,
            });
        }
    };

    const verifyOtp = async ({ mobileNumber, countryCode, otp, setError }) => {
        try {
            const response = await APIV5.post(`/restaurants/32/verify-otp`, {
                mobile_number: mobileNumber,
                country_code: countryCode,
                otp: Number(otp),
            });
            if (response?.success) {
                return response;
            }
        } catch (error) {
            setError('mobile_number', {
                message: error?.response?.data?.message ?? error?.message,
            });
        }
    };

    const handleInput = () => {
        if (!(mobileNumber && isValidMobileNumber)) {
            setError('mobile_number', {
                message: !mobileNumber ? 'Mobile Number is required' : 'Please Enter Valid Mobile Number',
            });
            return;
        }
        if (isShowInputView && !(otp && isValidOtp)) {
            setError('mobile_number', {
                message: !otp ? 'OTP is required' : 'Please Enter Valid OTP',
            });
            return;
        }
        if (!isShowInputView) {
            otpGetFunc();
        } else {
            verifyOtp({ mobileNumber, countryCode: `+${countryData?.code}`, otp, setError }).then((response) => {
                if (response?.success) {
                    setIsValidInput(response?.success);
                    setValue('validatedNumber', response?.phone_details?.mobile_number);
                    setValue('otp', '');

                    clearErrors('mobile_number');
                    clearErrors('validatedNumber');
                    setIsShowMobileNumberPopup(false);
                    setValue('mobile_number_id', response?.phone_details?.id);
                }
            });
        }
    };

    const otpGetFunc = () => {
        getOtp({ mobileNumber, countryCode: `+${countryData.code}`, setError }).then((response) => {
            response?.success && setIsShowInputView(true);
            setSeconds(30);
        });
    };
    const handleClose = () => {
        clearErrors('mobile_number');
        setIsShowMobileNumberPopup(false);
        setCountryData(countryCodes.find((country) => '+' + country?.code == oldCountryCode));
        setValue('mobileNumber', getValues('validatedNumber'));
    };
    const handleBack = () => {
        clearErrors('mobile_number');
        setCountryData(countryCodes.find((country) => '+' + country?.code == oldCountryCode));
        setIsShowInputView(false);
    };

    useEffect(() => {
        if (isShowInputView && seconds > 0) {
            const timerId = setTimeout(() => setSeconds(seconds - 1), 1000);
            return () => clearTimeout(timerId);
        }
    }, [seconds, isShowInputView]);

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleInput();
        }
    };

    return (
        <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex p-4  overflow-auto '>
            <div className='max-w-[630px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto  lg:max-w-[439px] '>
                {/* {IsMobileScreen() && (
                    <div
                        className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                        onClick={isShowInputView ? handleBack : handleClose}>
                        <LeftArrow className='rotate-90' />
                        <span className='pl-1'>Back to {isShowInputView ? 'Mobile Number' : 'user list'}</span>
                    </div>
                )} */}
                <div className='flex flex-row justify-between items-center border-neutral-300 mb-6 lg:mb-4'>
                    <div className='flex flex-row'>
                        {/* {!IsMobileScreen() && isShowInputView && (
                            <div
                                className='cursor-pointer'
                                onClick={handleBack}>
                                <LeftArrow className='rotate-90' />
                            </div>
                        )} */}
                        <span className='paragraph-large-medium'>Validate User</span>
                    </div>

                    <div
                        onClick={handleClose}
                        className=' cursor-pointer'>
                        <CloseIcon />
                    </div>
                </div>

                <div className='max-w-[480px] relative mb-16 lg:block '>
                    <InputField
                        inputValue={!isShowInputView ? mobileNumber : otp}
                        setValue={setValue}
                        showMobileIcon={isShowInputView}
                        isMobileNumberPage={!isShowInputView}
                        isValidInput={isValidInput}
                        setIsValidInput={setIsValidInput}
                        paddingLeft={!isShowInputView ? 'pl-[105px]' : null}
                        clearErrors={clearErrors}
                        onKeyDown={onKeyDown}
                        mobileNumberFIeldName={'mobileNumber'}
                        otpFieldName={'otp'}
                    />
                    {!isShowInputView && (
                        <div
                            className={`phoneinputbox absolute ${
                                errors?.mobile_number ? 'bottom-8' : 'bottom-3'
                            } left-4`}>
                            <PhoneInputDropdown
                                value={countryData.code}
                                setCountryData={setCountryData}
                                countryData={countryData}
                            />
                        </div>
                    )}
                    {isShowInputView && (
                        <div className='flex flex-row mt-3'>
                            {seconds > 0 && <p>Resend OTP in {seconds} seconds</p>}
                            <button
                                className={`phoneinputbox pl-2 text-neutral-400 ${
                                    seconds == 0 && 'text-primary-500 cursor-pointer'
                                } cursor-default`}
                                onClick={() => seconds == 0 && otpGetFunc()}
                                disabled={seconds != 0}>
                                Resend OTP
                            </button>
                        </div>
                    )}

                    {errors?.mobile_number && <ErrorMessage errors={errors?.mobile_number} />}
                </div>
                <div className='flex flex-row justify-between float-right '>
                    <div
                        className='min-w-[164px] cursor-pointer'
                        onClick={() => handleInput()}>
                        <LargePrimaryButton
                            label={isShowInputView ? 'Proceed' : 'Next'}
                            isDefault={false}
                            // disabled={mobileNumber == watch('validatedNumber')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
