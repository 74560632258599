import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ToggleSwitch from '../../../../Components/ToggleSwitch/ToggleSwitch';
import { IsMobileScreen } from '../../../../Constants/Constants';
import ListViewOfferLink from '../../../../Components/LinkOutlets/ListViewOfferLink';
import { CheckBoxWithoutLabels } from '../../../../Components/FormControl/FormControls';

export default function LinkOutletTable(props) {
    const { setLinkUnlinkOutletList, list, linkUnlinkOutletList, setIsDirty } = props;
    const selectedLanguageId = useSelector((state) => state.langauge.languageId);
    const isMobileScreen = IsMobileScreen();

    const [selectedOutlets, setSelectedOutlets] = useState([]);

    useEffect(() => {
        const initiallySelectedOutlets = list
            .filter((outlet) => checkIsOutletLinked(outlet.outletId))
            .map((outlet) => outlet.outletId);
        setSelectedOutlets(initiallySelectedOutlets);
    }, [list]);

    const checkIsOutletLinked = (outletId) => {
        return linkUnlinkOutletList.linkedOutlets.includes(outletId);
    };

    const handleChangeLinking = (doLink, outletId) => {
        setIsDirty(true);
        setLinkUnlinkOutletList((prevState) => {
            const updatedList = { ...prevState };

            if (doLink) {
                updatedList.linkedOutlets = [...prevState.linkedOutlets, outletId];
                updatedList.unLinkedOutlets = prevState.unLinkedOutlets.filter((id) => id !== outletId);
            } else {
                updatedList.unLinkedOutlets = [...prevState.unLinkedOutlets, outletId];
                updatedList.linkedOutlets = prevState.linkedOutlets.filter((id) => id !== outletId);
            }

            return updatedList;
        });

        setSelectedOutlets((prevState) => {
            if (doLink) {
                return [...prevState, outletId];
            } else {
                return prevState.filter((id) => id !== outletId);
            }
        });
    };

    const formatAddress = (item) => {
        const state = item?.outletData?.outlet_address.translations.state[selectedLanguageId];
        const city = item?.outletData?.outlet_address.translations.city[selectedLanguageId];
        const address = `${item.outletData.outlet_address.translations.address_line_1[selectedLanguageId]}, ${item.outletData.outlet_address.translations.address_line_2[selectedLanguageId]}`;
        return { state, city, address };
    };

    const handleSelectAllOutlet = () => {
        const allOutletIds = list.map((outlet) => outlet.outletId);
        if (selectedOutlets.length === list.length) {
            // Unselect all
            setSelectedOutlets([]);
            setLinkUnlinkOutletList((prevState) => ({
                ...prevState,
                linkedOutlets: [],
                unLinkedOutlets: allOutletIds,
            }));
        } else {
            // Select all
            setSelectedOutlets(allOutletIds);
            setLinkUnlinkOutletList((prevState) => ({
                ...prevState,
                linkedOutlets: allOutletIds,
                unLinkedOutlets: [],
            }));
        }
    };

    const isAllSelected = linkUnlinkOutletList.linkedOutlets.length === list.length;

    return (
        <>
            {!isMobileScreen ? (
                <div className='w-full border border-neutral-300 rounded-lg overflow-x-auto scrollbar-style'>
                    <table className='w-full break-words'>
                        <thead>
                            <tr className='paragraph-overline-small text-neutral-700 shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                <th className='px-6 min-w-[90px]'>
                                    <div className='flex flex-row items-center gap-2 -ml-2'>
                                        <CheckBoxWithoutLabels
                                            onChange={handleSelectAllOutlet}
                                            isChecked={isAllSelected}
                                        />
                                        STATUS
                                    </div>
                                </th>
                                <th className='px-6 min-w-[302px]'>OUTLET NAME</th>
                                <th className='px-6 min-w-[102px] lg:min-w-[135px]'>STATE</th>
                                <th className='px-6 min-w-[92px] lg:min-w-[151px]'>CITY</th>
                                <th className='px-6 min-w-[334px]'>ADDRESS</th>
                            </tr>
                        </thead>

                        <tbody>
                            {list.map((el, index) => {
                                const { state, city, address } = formatAddress(el);
                                const isOutletLinked = checkIsOutletLinked(el?.outletId);

                                return (
                                    <tr
                                        className='paragraph-small-regular border-t first:border-none justify-center h-[70px]'
                                        key={index}>
                                        <td className='px-6'>
                                            <ToggleSwitch
                                                canChange
                                                isEnable={isOutletLinked}
                                                setIsEnabled={(value) => handleChangeLinking(value, el.outletId)}
                                            />
                                        </td>
                                        <td className='px-6'>{el.displayName}</td>
                                        <td className='px-6'>{state}</td>
                                        <td className='px-6'>{city}</td>
                                        <td className='px-6'>{address}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className=''>
                    <div className='flex flex-row items-center gap-2'>
                        <CheckBoxWithoutLabels
                            onChange={handleSelectAllOutlet}
                            isChecked={isAllSelected}
                        />

                        <span className='paragraph-small-medium text-neutral-700'>Link all outlet</span>
                    </div>

                    {list.map((el, index) => {
                        const { state, city, address } = formatAddress(el);
                        const isOutletLinked = checkIsOutletLinked(el?.outletId);
                        return (
                            <div
                                className='mt-2'
                                key={index}>
                                <ListViewOfferLink
                                    outletName={el.displayName}
                                    state={state}
                                    city={city}
                                    address={address}
                                    isEnable={isOutletLinked}
                                    setIsEnabled={(value) => handleChangeLinking(value, el.outletId)}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}
