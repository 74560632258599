export const tabs = [
    { label: 'Offer type', value: 'offer-type' },
    { label: 'Basic details', value: 'basic-details' },
    { label: 'Configure', value: 'configure' },
    { label: 'Clubbing', value: 'clubbing' },
    { label: 'Visibility and linking', value: 'visibility-linking' },
    { label: 'Validity and applicability', value: 'validity-applicability' },
    { label: 'Offer timing', value: 'offer-timing' },
    { label: 'Terms conditions', value: 'terms-conditions' },
    { label: 'Item list', value: 'item-list' },
    { label: 'Notification settings', value: 'notification-settings', correspondingField: [] },
];

export const offerTypes = [
    { label: 'Flat discount', value: 'flat_discount' },
    { label: 'Percentage discount', value: 'percentage_discount' },
    { label: 'Free delivery', value: 'free_delivery' },
    { label: 'Get an item', value: 'free_items' },
    { label: 'Buy X get Y free', value: 'buy_x_get_y' },
    { label: 'Menu discount', value: 'menu_discount' },
    { label: 'BOGO', value: 'buy_one_get_one_free' },
    { label: 'Coupled offer', value: 'coupled_offer' },
    { label: 'Value deal', value: 'combo_item_price_deal' },
    { label: 'BOGO View', value: 'BOGO_View' },
];
