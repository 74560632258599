import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import APIV2 from '../../api/axios/APIV2';
import { useNavigate, useParams } from 'react-router-dom';
import APIV3 from '../../api/axios/APIV3';
import CreateOffer from '../CreateOffer/CreateOffer';
import _ from 'lodash';
import APIV5 from '../../api/axios/APIV5';
import { format, parse } from 'date-fns';
import Loader from './Component/Loader';
import { preferenceMenuItem } from '../../Constants/Constants';

export default function EditOffer() {
    const { section, id } = useParams();

    const orderingMode = useSelector((state) => state.outlet.orderingModes);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [defaultParams, setDefaultParams] = useState({});

    const implementationPresetMenuItems = [
        { label: 'Applicable on all categories', value: 'all_categories' },
        { label: 'Applicable on specific categories', value: 'specific_categories' },
        { label: 'Applicable on specific items', value: 'specific_items' },
    ];

    const menuItems = [
        { label: 'Flat discount', value: 'flat_discount' },
        { label: 'Percentage discount', value: 'percentage_discount' },
        { label: 'Free delivery', value: 'free_delivery' },
        { label: 'Get an item', value: 'free_items' },
        { label: 'Buy X get Y free', value: 'buy_x_get_y' },
        { label: 'Menu discount', value: 'menu_discount' },
        { label: 'BOGO', value: 'buy_one_get_one_free' },
        { label: 'Coupled offer', value: 'coupled_offer' },
        { label: 'Value deal', value: 'combo_item_price_deal' },
        { label: 'BOGO View', value: 'BOGO_View' },
    ];

    const menuItemTiming = [
        { label: 'Full-time', value: 'no_time_slots' },
        { label: 'Specific time for all days', value: 'same_time_slot_for_all_days' },
        { label: 'Specific time for each day', value: 'different_time_slots_for_different_days' },
    ];

    const formatScreen = (screen) => {
        return { label: screen.charAt(0).toUpperCase() + screen.slice(1).replace(/-/g, ' '), value: screen };
    };

    const fetchNotificationDetails = async (id) => {
        try {
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}/notifications/${id}`);

            return formateNotificationDetail(response.notifications);
        } catch (error) {
            console.log('error : ', error);
        }
    };

    const formateNotificationDetail = async (rowNotificationData) => {
        let data = {};

        data.notification_id = rowNotificationData.id;

        data.send_notification = true;

        data.isNotificationAvailable = true;

        Object.keys(rowNotificationData.translations).forEach((key) => {
            const translation = rowNotificationData.translations[key];

            if (Array.isArray(translation) && translation.length === 0) {
                // Handle empty array case
                data[`notification_${key}`] = [];
            } else if (typeof translation === 'object' && translation !== null) {
                const maxIndex = Math.max(...Object.keys(translation).map(Number));

                if (maxIndex === -Infinity) {
                    // Handle the case where the object is empty
                    data[`notification_${key}`] = [];
                } else {
                    data[`notification_${key}`] = new Array(maxIndex + 1).fill(null);
                    Object.keys(translation).forEach((index) => {
                        data[`notification_${key}`][index] = translation[index];
                    });
                }
            }
        });

        data.notification_screen = formatScreen(rowNotificationData.screen);

        const parsedDate = parse(rowNotificationData.time_to_send, 'yyyy-MM-dd HH:mm', new Date());

        data.notification_selected_date = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSX");

        data.notification_selected_time = {
            label: format(parsedDate, 'hh:mm a'),
            value: format(parsedDate, 'HH:mm'),
        };

        return data;
    };

    const formateData = async (rowData) => {
        let data = { ...rowData, isEditOffer: true };

        const notificationDetails = !!rowData.notification_id
            ? await fetchNotificationDetails(rowData.notification_id)
            : { isNotificationAvailable: false };

        data = { ...data, ...notificationDetails };

        data = { ...data, offer_type: menuItems.find((el) => el.value === data.offer_type) };

        data = {
            ...data,
            allow_offer_clubbing: !data.allow_offer_clubbing
                ? { label: 'No', value: false }
                : { label: 'Yes', value: true },
        };

        data = {
            ...data,
            auto_apply: !data.auto_apply ? { label: 'No', value: false } : { label: 'Yes', value: true },
        };

        data = {
            ...data,
            promo_consolidation: !data.promo_consolidation
                ? { label: 'No', value: false }
                : { label: 'Yes', value: true },
        };

        data = {
            ...data,
            display_in_cart_tab: !!data.offer_display?.display_in_cart_tab
                ? { label: 'Yes', value: true }
                : { label: 'No', value: false },
            display_in_offers_tab: !!data.offer_display?.display_in_offers_tab
                ? { label: 'Yes', value: true }
                : { label: 'No', value: false },
        };

        const translations = {};

        for (const key in data.translations) {
            translations[key] = [null];
            for (const subKey in data.translations[key]) {
                translations[key].push(data.translations[key][subKey]);
            }
        }

        data = { ...data, translations };

        data = {
            ...data,
            offer_timing_timingType: menuItemTiming.find((el) => el.value === data.time_slot_setting),
        };

        data = { ...data, hide_on_exhaustion: data.hide_on_exhaustion ? 1 : 0 };

        data = {
            ...data,
            is_minimum_order_count_enable: !data.minimum_cart_amount_value
                ? { label: 'No', value: false }
                : { label: 'Yes', value: true },
        };

        data = {
            ...data,
            do_add_user: !!data?.offer_customers?.length
                ? { label: 'Yes', value: true }
                : { label: 'No', value: false },
        };

        if (data.offer_customers.length) {
            const transformedList = data.offer_customers.map((el) => ({
                label: `${el.name} - ${el.mobile_number}`,
                value: el.id,
                ...el,
            }));

            data = { ...data, customer_ids: transformedList };
        }

        data = {
            ...data,
            has_coupon_limit: !!data.max_usage_count ? { label: 'Yes', value: true } : { label: 'No', value: false },
        };

        data = {
            ...data,
            has_usage_number: !!data.max_usage_count_per_user
                ? { label: 'Yes', value: true }
                : { label: 'No', value: false },
        };

        data = {
            ...data,
            has_offer_validity: !!data.validate_to ? { label: 'Yes', value: true } : { label: 'No', value: false },
        };

        if (data.time_slot_setting === 'different_time_slots_for_different_days') {
            const schedules = Array.from({ length: 7 }, () => []);

            data.schedules.forEach((schedule) => {
                const dayIndex = schedule.time_slots.day_of_week - 1;
                schedules[dayIndex].push({
                    start_time: schedule.time_slots.start_time,
                    end_time: schedule.time_slots.end_time,
                });
            });

            data = {
                ...data,
                offer_timing: schedules,
            };
        }

        if (data.time_slot_setting === 'same_time_slot_for_all_days') {
            const schedule = data.schedules
                .filter((slot) => slot.time_slots.day_of_week === 1)
                .map((slot) => ({
                    start_time: slot.time_slots.start_time,
                    end_time: slot.time_slots.end_time,
                }));

            data = {
                ...data,
                offer_timing: schedule,
            };
        }

        const ordering_modes = data.ordering_modes
            .map(({ restaurant_ordering_mode_name, restaurant_ordering_mode_id }) => {
                const mode = orderingMode.find((mode) => mode.id === restaurant_ordering_mode_id);
                if (mode) {
                    return {
                        label: restaurant_ordering_mode_name,
                        value: mode,
                    };
                }
                return null;
            })
            .filter(Boolean);

        data = { ...data, ordering_modes };

        if (data.offer_categories.length) {
            data.implementation_preset = implementationPresetMenuItems[1];

            data.selected_category = data.offer_categories?.map((el) => ({ ...el, label: el?.internal_name }));
        } else if (data.required_items.length) {
            data.implementation_preset = implementationPresetMenuItems[2];
            data?.required_items?.map((el, index) => data?.selected_item?.[index]?.item);
        } else {
            data.implementation_preset = implementationPresetMenuItems[0];
        }

        data.buy_x_quantity = data.min_cart_item_quantity;

        data.get_y_quantity = data.quantity_of_free_item;

        data.apply_on_addon = data.apply_on_addon ? { label: 'Yes', value: true } : { label: 'No', value: false };

        data.max_discount_amount = data.max_discount_amount ?? 0;

        data.do_add_maximum_discount = data.max_discount_amount
            ? { label: 'Yes', value: true }
            : { label: 'No', value: false };

        data.minimum_cart_amount_value = data.min_cart_amount;

        data.is_minimum_order_count_enable = data.min_cart_amount
            ? { label: 'Yes', value: true }
            : { label: 'No', value: false };

        data.free_items = data.free_items.map((el) => {
            return {
                item_id: el.item.item_id,
                label: el.item.internal_name,
                quantity: el.quantity,
                display_name: el.item.internal_name,
            };
        });

        data.combo_item_price_deal = data?.combo_deal_item_index;

        if (!!data?.min_required_order_counts || !!data?.max_required_order_counts) {
            data.hasMinMaxOrderCount = preferenceMenuItem[0];
        } else {
            data.hasMinMaxOrderCount = preferenceMenuItem[1];
        }

        if (data?.linked_categories?.length) {
            const categories = await fetchLinkedCategory(data.linked_categories[0].primary_category_id);

            const categoryWithInternalName = await fetchCategoryName();

            const selectedPrimaryCategory = categoryWithInternalName.find(
                (el) => el.category_id === data.linked_categories[0].primary_category_id
            );

            data.selected_item_category = {};

            const formattedSelectedPrimaryCategory = {
                label: selectedPrimaryCategory.internal_name,
                value: {
                    category_id: selectedPrimaryCategory.category_id,
                    parent_id: selectedPrimaryCategory.parent_id,
                    internal_name: selectedPrimaryCategory.internal_name,
                    translations: selectedPrimaryCategory.translations,
                },
                ...selectedPrimaryCategory,
            };

            data.selected_item_category.category = formattedSelectedPrimaryCategory;

            if (data?.linked_categories?.[0]?.module_name === 'variant') {
                data.selected_item_category.list = formateLinkedVariant(
                    data.linked_categories,
                    categories,
                    categoryWithInternalName
                );
            } else {
                data.selected_item_category.list = getLinkedItems(
                    data.linked_categories,
                    categories,
                    categoryWithInternalName
                );
            }
        }

        setDefaultParams(data);
    };

    const formateLinkedVariant = (linkedCategory, categories, categoryWithInternalName) => {
        const formattedLinkVariant = categories.map((category) => {
            category.variants.forEach((variant) => {
                variant.options.forEach((option) => {
                    const linked = linkedCategory.find((link) => link.module_id === option.id);
                    if (linked) {
                        const internalCategory = categoryWithInternalName.find(
                            (cat) => cat.category_id === linked.secondary_category_id
                        );
                        if (internalCategory) {
                            option.selectedOption = {
                                label: internalCategory.internal_name,
                                value: internalCategory,
                                ...internalCategory,
                            };
                        }
                    }
                });
            });
            return category;
        });

        return formattedLinkVariant;
    };

    const getLinkedItems = (linkedCategory, categories, categoryWithInternalName) => {
        const categoryList = [];

        categories.forEach((category) => {
            const linkedItem = linkedCategory.find((linked) => linked.module_id === category.item_id);

            if (linkedItem) {
                const interNameCategory = categoryWithInternalName.find(
                    (inter) => inter.category_id === linkedItem.secondary_category_id
                );

                if (interNameCategory) {
                    categoryList.push({
                        ...category,
                        selectedOption: {
                            label: interNameCategory.internal_name,
                            value: {
                                category_id: interNameCategory.category_id,
                                parent_id: interNameCategory.parent_id,
                                internal_name: interNameCategory.internal_name,
                                translations: interNameCategory.translations,
                            },
                            ...interNameCategory,
                        },
                    });
                } else {
                    categoryList.push(category);
                }
            } else {
                categoryList.push(category);
            }
        });

        return categoryList;
    };

    const fetchLinkedCategory = async (linkedCategoryId) => {
        try {
            const response = await APIV3.get(
                `/restaurants/${selectedRestaurantId}/categories/${linkedCategoryId}/items`,
                { params: { language_id: 1, is_recursive: 1 } }
            );

            return response.items;
        } catch (error) {
            console.log(error);
        }
    };

    const selectedLanguageId = useSelector((state) => state.langauge.languageId);

    const fetchCategoryName = async (params) => {
        try {
            const response = await APIV3.get(
                `/restaurants/${selectedRestaurantId}/categories/internal-name?language_id=${selectedLanguageId}`
            );

            return response.categories;
        } catch (error) {
            console.log(error);
        }
    };

    const fetchOfferDetails = async () => {
        try {
            const response = await APIV2.get(`/restaurants/${selectedRestaurantId}/offers/${id}`);

            formateData(response.offers);
        } catch (error) {
            console.log(error);
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        fetchOfferDetails();

        section !== 'offer-type' && navigate(`/offers/edit/${id}/offer-type`);

        // eslint-disable-next-line
    }, []);

    return <>{!_.isEmpty(defaultParams) ? <CreateOffer defaultValue={defaultParams} /> : <Loader />}</>;
}
