import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as DownArrowIcon } from '../../Assets/chevron-down.svg';
import { ReactComponent as SearchIcon } from '../../Assets/search.svg';
import { useSelector } from 'react-redux';

export default function DropdownDifferentFirstMenuItem(props) {
    const {
        label,
        subTitle,
        type,
        searchItemPlaceholder,
        firstMenuItem,
        menuItems,
        openFromBottom,
        handleClickItem,
        watch,
        name,
    } = props;

    const [searchValue, setSearchValue] = useState('');

    const filteredMenuItems = menuItems.filter((item) => item.label.toLowerCase().includes(searchValue.toLowerCase()));

    return (
        <>
            <div className='flex mb-1 items-center'>
                <label className='paragraph-small-medium'>{label}</label>
                <span className='paragraph-small-italic ml-0.5 text-neutral-500'>
                    {subTitle} (Selected - {watch(name)?.length ?? 0})
                </span>
            </div>

            <div className='relative'>
                <Menu as='div'>
                    <div className='dropdown-icon'>
                        <Menu.Button className='shadow-xSmall w-full justify-center mobile:max-w-full rounded-md outline-none border h-12 appearance-none px-4 border-neutral-300 paragraph-small-regular'>
                            <div className='flex flex-row justify-between items-center'>
                                <div className={`${watch(name)?.length === 0 && 'text-neutral-300'} flex`}>
                                    {watch(name)?.length === 0
                                        ? 'Select an item'
                                        : `${
                                              watch(name)?.length !== undefined ? watch(name)?.length : `Not`
                                          } Selected`}
                                </div>

                                <DownArrowIcon
                                    height={24}
                                    width={24}
                                    className='drop-down-icon-rotate'
                                />
                            </div>
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items
                            className={`absolute left-0 right-0 mt-2 px-4 py-2 border paragraph-small-regular rounded-md shadow-medium bg-shades-50 font-normal z-50 lg:bottom-full lg:mb-2 ${
                                openFromBottom && 'bottom-full mb-2'
                            } overflow-y-auto max-h-40`}>
                            {type === 'searchDropdown' && (
                                <div className='w-full pt-2 mb-2 flex flex-row items-center'>
                                    <SearchIcon />
                                    <input
                                        placeholder={searchItemPlaceholder}
                                        className='ml-2 placeholder:paragraph-small-regular placeholder:text-neutral-300 outline-none w-full'
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                </div>
                            )}

                            {firstMenuItem && <div className='w-full pt-2 mb-2'>{firstMenuItem}</div>}

                            {filteredMenuItems.map((el, index) => (
                                <div
                                    className={`w-full pt-2 mb-2 items-center group flex flex-row justify-between ${
                                        watch(name)?.includes(el.value) ? 'bg-primary-100 rounded-md p-2' : ''
                                    }`}
                                    key={index}>
                                    <div
                                        className='w-full cursor-pointer flex items-center'
                                        onClick={() => handleClickItem(el)}>
                                        <Menu.Item>
                                            <span className='hover:text-primary-500 active:paragraph-small-medium'>
                                                {el.label}
                                            </span>
                                        </Menu.Item>
                                    </div>
                                    {watch(name)?.includes(el.value) && (
                                        <span className='ml-2 text-primary-500 font-medium'>&#10004;</span>
                                    )}
                                </div>
                            ))}
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </>
    );
}
