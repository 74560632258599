import React, { useState } from 'react';

import { IsMobileScreen } from '../../../Constants/Constants';
import { ReactComponent as LeftArrowIcon } from '../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { RadioButton } from '../../../Components/FormControl/FormControls';
import { InputArea } from '../../../Components/InputField/InputField';
import { LargePrimaryButton, LargeTertiaryButton } from '../../../Components/Buttons/Button';
import APIV5 from '../../../api/axios/APIV5';
import { useSelector } from 'react-redux';

export default function RejectComplaintPopup(props) {
    const { handleClickClose, isLoading } = props;

    const isMobileScreen = IsMobileScreen();

    const [selectedReason, setSelectedReason] = useState(2);
    const [comment, setComment] = useState('');
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const [error, setError] = useState('');

    function getRejectionReasonText(reasonId) {
        switch (reasonId) {
            case 2:
                return 'Need clear photos to understand the issue';
            case 9:
                return 'Complaint is invalid';
            default:
                return 'Any other reason';
        }
    }
    const handleSubmit = async () => {
        try {
            setError('');

            if (selectedReason === 4 && !comment.trim()) {
                setError('Please enter a reason for rejection');
                return;
            }

            const response = await APIV5.put(`/restaurants/${selectedRestaurantId}/complaints/${2}`, {
                status: 'reject',
                complaint_reject_reason: selectedReason === 4 ? comment : getRejectionReasonText(selectedReason),
            });
            handleClickClose();
        } catch (error) {
            setError('Failed to submit rejection. Please try again.');
            console.error('Error rejecting complaint:', error);
        }
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center overflow-auto md:bg-white md:relative p-4'>
                <div className='bg-white w-[652px] md:w-full rounded-xl px-8 py-6 m-auto md:p-0'>
                    {isMobileScreen ? (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='paragraph-medium-medium pl-1'>Back to order rating</span>
                        </div>
                    ) : (
                        <div className='flex flex-row justify-between items-center mb-4 border-b border-neutral-300 pb-4'>
                            <div className=''>
                                <div className='paragraph-large-medium'>Reject complaint</div>
                                <div className='paragraph-medium-italic text-neutral-500'>
                                    Select specific reason for rejecting complaint
                                </div>
                            </div>

                            <div
                                className='cursor-pointer'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </div>
                        </div>
                    )}

                    {error && <div className='text-red-500 mb-4'>{error}</div>}

                    <RadioButton
                        marginB='3'
                        label='Need clear photos to understand the issue'
                        optionId={2}
                        handleOptionChange={() => setSelectedReason(2)}
                        checked={selectedReason === 2}
                    />

                    <RadioButton
                        marginB='3'
                        label='Complaint is invalid'
                        optionId={9}
                        handleOptionChange={() => setSelectedReason(9)}
                        checked={selectedReason === 9}
                    />

                    <RadioButton
                        marginB='3'
                        label='Any other reason'
                        optionId={4}
                        handleOptionChange={() => setSelectedReason(4)}
                        checked={selectedReason === 4}
                    />

                    {selectedReason === 4 && (
                        <InputArea
                            labelMarginB='mb-2 mt-4'
                            label='Enter reason'
                            placeholder='Please enter reason to reject complaint'
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    )}

                    <div className='flex flex-row items-center gap-4 mt-9'>
                        <div className='w-1/2'>
                            <span className='paragraph-large-medium'>Step 2/2</span>
                        </div>

                        <div className='flex flex-row w-1/2 gap-3'>
                            <div
                                className='w-full'
                                onClick={handleClickClose}>
                                <LargeTertiaryButton label='Cancel' />
                            </div>

                            <div
                                className='w-full'
                                onClick={handleSubmit}>
                                <LargePrimaryButton
                                    label={isLoading ? 'Submitting...' : 'Accept'}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
