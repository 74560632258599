import React, { useState, useEffect } from 'react';
import { ReactComponent as OrderRatingIcon } from '../../Assets/order-ratings.svg';
import { ReactComponent as DownArrow } from '../../Assets/chevron-down.svg';
import { ReactComponent as EditIcon } from '../../Assets/edit.svg';
import { CheckBoxWithoutLabels } from '../FormControl/FormControls';

export default function ListViewRider(props) {
    const {
        riderName,
        riderRating,
        handleClickViewDetail,
        mobileNumber,
        deliveryRadius,
        joinDate,
        riderId,
        showCheckbox,
        isEditTable,
        handleCheckboxChange,
        checkedItems,
        fetchEditRiderDetails,
        setAddOrEdit,
        setShowAddRiderPage,
    } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };

    return (
        <>
            <div className='w-full px-4 py-2.5 border border-neutral-300 rounded-md mb-4'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickShowDetails}>
                    <div className='flex flex-row items-center'>
                        {showCheckbox && !isEditTable && (
                            <div className='mr-4 cursor-pointer'>
                                <CheckBoxWithoutLabels
                                    onChange={(isChecked) => handleCheckboxChange(riderId, isChecked)}
                                    isChecked={checkedItems.includes(riderId)}
                                />
                            </div>
                        )}
                        {isEditTable && !showCheckbox && (
                            <div
                                className='mr-4 cursor-pointer z-[7]'
                                onClick={() => {
                                    setShowAddRiderPage(true);
                                    fetchEditRiderDetails(riderId);
                                    setAddOrEdit('edit');
                                }}>
                                <EditIcon />
                            </div>
                        )}
                        <div>
                            <h3 className='paragraph-overline-small text-neutral-700 mb-0.5'>RIDER NAME:</h3>
                            <span className='paragraph-small-regular'>{riderName}</span>
                        </div>
                    </div>
                    <div className='flex flex-row items-center'>
                        {!isShowDetails && (
                            <div className='flex flex-row items-center mr-1'>
                                <OrderRatingIcon
                                    height={20}
                                    width={20}
                                    fill={riderRating > 3 ? '#EBF6F5' : riderRating === 3 ? '#FFF5E3' : '#FEF2F2'}
                                    stroke={riderRating > 3 ? '#3D8C82' : riderRating === 3 ? '#FFA704' : '#EF4444'}
                                />
                                <span className='paragraph-small-regular ml-1'>{riderRating}</span>
                            </div>
                        )}
                        <div className={`${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>

                {isShowDetails && (
                    <div className='mt-1.5'>
                        <div className='flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>MOBILE NUMBER:</span>
                            <span className='paragraph-small-regular ml-1'>{mobileNumber}</span>
                        </div>
                        <div className='pt-2 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>JOIN DATE:</span>
                            <span className='paragraph-small-regular ml-1'>{joinDate}</span>
                        </div>
                        <div className='pt-2 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>DELIVERY RADIUS:</span>
                            <span className='paragraph-small-regular ml-1'>{deliveryRadius} kms</span>
                        </div>
                        <div className='pt-2 flex flex-row justify-between mobile:block'>
                            <div className='flex flex-row'>
                                <span className='paragraph-overline-small text-neutral-700'>AVG. RIDER RATING:</span>
                                <span className='paragraph-small-regular ml-1'>
                                    <div className='flex flex-row items-center'>
                                        <OrderRatingIcon
                                            className='h-5 w-5 mr-1'
                                            fill={
                                                riderRating > 3 ? '#EBF6F5' : riderRating === 3 ? '#FFF5E3' : '#FEF2F2'
                                            }
                                            stroke={
                                                riderRating > 3 ? '#3D8C82' : riderRating === 3 ? '#FFA704' : '#EF4444'
                                            }
                                        />
                                        <span className='paragraph-small-regular'>{riderRating}</span>
                                    </div>
                                </span>
                            </div>
                            <span
                                className='paragraph-small-italic text-primary-500 cursor-pointer'
                                onClick={() => handleClickViewDetail(riderId)}>
                                View details
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
