import React from 'react';
import ListViewBillPayment from './ListViewBillPayment';
import { SingleLoader } from '../../../Components/ContentLoader/DropdownLoader';

export default function MobileListSection(props) {
    const { list, setOrderDetails, isListLoading, setPopupOrderId } = props;

    return (
        <>
            {isListLoading
                ? [...Array(5)].map((_, index) => (
                      <SingleLoader
                          className='h-[68px] w-full mt-2'
                          key={index}
                      />
                  ))
                : list.map((el, index) => (
                      <div
                          className='mt-2'
                          key={index}>
                          <ListViewBillPayment
                              handleClickViewDetails={() => {
                                  setPopupOrderId(el.id);
                                  setOrderDetails(el);
                              }}
                              {...el}
                          />
                      </div>
                  ))}
        </>
    );
}
