import React, { useEffect, useState, useRef } from 'react';
import PaginationWithNumber from '../../Components/Pagination/PaginationWithNumber';
import OrderDetailsPopup from '../../Components/CustomerOrderDetails/OrderDetailsPopup';
import ListSection from './Components/ListSection';
import Layout from './Components/Layout';
import { useSelector } from 'react-redux';
import APIV3 from '../../api/axios/APIV3';
import { format } from 'date-fns';
import _ from 'lodash';
import APIV5 from '../../api/axios/APIV5';
import RefundPopup from './Components/RefundPopup';

export default function BillPayment() {
    const [orderDetails, setOrderDetails] = useState();

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const today = new Date();

    const [isListLoading, setIsListLoading] = useState(true);

    const [isPopupLoading, setIsPopupLoading] = useState(false);

    const [popupOrderId, setPopupOrderId] = useState(null);

    const [popupDetails, setPopupDetails] = useState(null);

    const [rnnCode, setRnnCode] = useState(null);

    const [selectedPrinter, setSelectedPrinter] = useState(null);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [paginationState, setPaginationState] = useState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });

    const [billList, setBillList] = useState([]);

    const [showRefundPopup, setShowRefundPopup] = useState(false);

    const [selectedDates, setSelectedDates] = useState([today, today]);

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const [partialRefundAmount, setPartialRefundAmount] = useState(null);

    const defaultParams = () => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);

        return {
            start_date: formatDate(from_date),
            end_date: formatDate(to_date),
        };
    };
    const fetchBillList = async (params) => {
        try {
            setIsListLoading(true);
            const defaultParamsData = defaultParams();
            const response = await APIV3.get(`outlets/${selectedOutletId}/bill-payments`, {
                params: {
                    ...params,
                    ...defaultParamsData,
                    per_page: paginationState.resultPerPage,
                    page: paginationState.selectedPage,
                },
            });

            setBillList(response.customer_bill_payments.data);

            setPaginationState((prevState) => ({
                ...prevState,
                lastPage: response.customer_bill_payments.last_page,
                selectedPage: response.customer_bill_payments.current_page,
            }));
            setIsListLoading(false);
        } catch (error) {
            setIsListLoading(false);
            console.log(error);
        } finally {
            setIsListLoading(false);
        }
    };

    useEffect(() => {
        let params = {
            page: paginationState.selectedPage,
            per_page: paginationState.resultPerPage,
        };

        fetchBillList(params);

        // eslint-disable-next-line
    }, [selectedOutletId, paginationState.resultPerPage, paginationState.selectedPage, selectedDates]);

    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    const contentRef = useRef();

    const fetchOrderDetailPopup = async () => {
        try {
            setIsPopupLoading(true);
            const response = await APIV5.get(`outlets/${selectedOutletId}/customer-bill-payments/${popupOrderId}`);
            if (response.success) {
                setPopupDetails(response.customer_bill_payment_detail);
                if (response?.customer_bill_payment_detail?.refunds?.length) {
                    const responseTwo = await APIV5.get(
                        `outlets/${selectedOutletId}/customer-bill-payments/${popupOrderId}/refund-status`
                    );
                    if (responseTwo.success) {
                        setRnnCode(responseTwo?.refund_response?.body?.refundDetailInfoList?.[0]?.rrn);
                    }
                }
            }
            setIsPopupLoading(false);
        } catch (error) {
            setIsPopupLoading(false);
            console.log('error ===> ', error);
        }
    };

    useEffect(() => {
        if (popupOrderId) {
            fetchOrderDetailPopup(popupOrderId);
        }
    }, [popupOrderId]);

    const handleSelection = (value) => {
        setSelectedPrinter(value?.value);
    };

    const handleRefund = async () => {
        try {
            const response = await APIV3.post(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/customer-bill-payment/${orderDetails?.id}/refund`,
                { amount: partialRefundAmount ?? popupDetails?.amount }
            );
            if (response.success) {
                fetchOrderDetailPopup();
                setShowRefundPopup(false);
            }
        } catch (error) {
            console.log('error : ', error);
        }
    };

    const popupData = [
        {
            item: 'Order Bill name',
            value: popupDetails && popupDetails?.order_bill_name ? popupDetails?.order_bill_name : 'N/A',
        },
        {
            item: 'Order date',
            value: popupDetails && popupDetails?.order_date ? popupDetails?.order_date : 'N/A',
        },
        {
            item: 'Payment method',
            value:
                popupDetails && popupDetails?.payment_method?.payment_method
                    ? popupDetails?.payment_method?.payment_method
                    : 'N/A',
        },
        {
            item: `Customer's order`,
            value: popupDetails && popupDetails?.customer_orders_count ? popupDetails?.customer_orders_count : 'N/A',
        },
        {
            item: 'Order time',
            value: popupDetails && popupDetails?.order_time ? popupDetails?.order_time : 'N/A',
        },
        {
            item: 'Order amount',
            value: popupDetails && popupDetails?.amount ? popupDetails?.amount : '',
        },
    ];

    const handleClickClose = () => {
        setPartialRefundAmount(null);
        setShowRefundPopup(false);
    };

    const handleClickPrint = () => {
        const printWindow = window.open('');

        // Write the bill content to the new window
        printWindow.document.write(`
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Print Bill</title>
                <style>
                    /* Add any necessary styles here */
                    /* For example, hide unnecessary elements */
                    body * {
                        visibility: hidden;
                    }
                    #printInvoice, #printInvoice * {
                        visibility: visible;
                    }
                </style>
            </head>
            <body>
                ${'start'}
                ${'content'}
            </body>
            </html>
        `);

        printWindow.document.close();

        printWindow.print();
    };
    return (
        <>
            <Layout
                showCustomerDetailsPopup={!_.isEmpty(orderDetails)}
                setSelectedDates={setSelectedDates}
                selectedDates={selectedDates}>
                <ListSection
                    isListLoading={isListLoading}
                    billList={billList}
                    setPopupOrderId={setPopupOrderId}
                    setOrderDetails={setOrderDetails}
                />

                <div className='mt-4'>
                    <PaginationWithNumber
                        lastPage={paginationState.lastPage}
                        selectedPage={paginationState.selectedPage}
                        setSelectedPage={(value) => {
                            handlePagination({ selectedPage: value }, 'selectedPage');
                        }}
                        setResultPerPage={(value) => handlePagination({ resultPerPage: value }, 'resultPerPage')}
                        selectedResultPerpage={paginationState.resultPerPage}
                        isLoading={isListLoading}
                    />
                </div>
            </Layout>
            {popupOrderId && (
                <OrderDetailsPopup
                    orderDetails={popupData}
                    code={orderDetails?.verification_code}
                    hasRefundOption={true}
                    page='billPayment'
                    handleClickClose={() => {
                        setPopupOrderId(null);
                        setOrderDetails(null);
                    }}
                    netTotal={orderDetails?.amount}
                    handleSelection={handleSelection}
                    isPopupLoading={isPopupLoading}
                    setShowRefundPopup={setShowRefundPopup}
                    refundDetails={popupDetails?.refunds}
                    handleClickPrint={handleClickPrint}
                    rnnCode={rnnCode}
                />
            )}

            {showRefundPopup && (
                <RefundPopup
                    handleClickClose={handleClickClose}
                    partialRefundAmount={partialRefundAmount}
                    setPartialRefundAmount={setPartialRefundAmount}
                    handleRefund={handleRefund}
                />
            )}
        </>
    );
}
