import { ReactComponent as Dashboard } from '../../../Assets/dashboard.svg';
import { ReactComponent as KdsIcon } from '../../../Assets/kitchen-display-system.svg';
import { ReactComponent as AbandonedCart } from '../../../Assets/abandoned-cart.svg';
import { ReactComponent as Menu } from '../../../Assets/menu.svg';
import { ReactComponent as MediaLibrary } from '../../../Assets/media-library.svg';
import { ReactComponent as Availability } from '../../../Assets/availability.svg';
import { ReactComponent as OrderRatings } from '../../../Assets/order-ratings.svg';
import { ReactComponent as Customers } from '../../../Assets/customers.svg';
import { ReactComponent as Notifications } from '../../../Assets/notifications.svg';
import { ReactComponent as Offers } from '../../../Assets/offers.svg';
import { ReactComponent as Banners } from '../../../Assets/banners.svg';
import { ReactComponent as Credit } from '../../../Assets/credits.svg';
import { ReactComponent as Settings } from '../../../Assets/settings.svg';
import { ReactComponent as Reports } from '../../../Assets/reports.svg';
import { ReactComponent as Riders } from '../../../Assets/riders.svg';
import { ReactComponent as TableManagement } from '../../../Assets/table-management.svg';
import { ReactComponent as Cashback } from '../../../Assets/cashback.svg';
import { ReactComponent as HelpDocumentation } from '../../../Assets/help-documentation.svg';
import { ReactComponent as LiveOrderIcon } from '../../../Assets/orders.svg';
import { ReactComponent as TableViewIcon } from '../../../Assets/tableView.svg';
import { ReactComponent as DeliveryIcon } from '../../../Assets/order.svg';
import { ReactComponent as WaiterIcon } from '../../../Assets/waiter_food_serving.svg';

export const links = [
    {
        group: [
            {
                name: 'Dashboard',
                icon: <Dashboard className='mx-auto' />,
                iconHover: (
                    <Dashboard
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/',
            },
        ],
    },
    {
        group: [
            {
                name: 'Orders',
                icon: <LiveOrderIcon className='mx-auto' />,
                iconHover: (
                    <LiveOrderIcon
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/orders/live',
                baseUrl: '/orders',
                group: 'pos',
            },
            {
                name: 'KDS',
                icon: <KdsIcon className='mx-auto' />,
                iconHover: (
                    <KdsIcon
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/kitchen-display-system',
            },
        ],
    },
    {
        group: [
            {
                name: 'POS',
                icon: <DeliveryIcon className='mx-auto' />,
                iconHover: (
                    <DeliveryIcon
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/pos',
                group: 'pos',
            },
            {
                name: 'Table View',
                icon: <TableViewIcon className='mx-auto' />,
                iconHover: (
                    <TableViewIcon
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/table-view',
                group: 'pos',
            },
        ],
    },
    {
        group: [
            {
                name: 'Item availability',
                icon: <Availability className='mx-auto' />,
                iconHover: (
                    <Availability
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/availability/dish',
                baseUrl: '/availability',
            },

            {
                name: 'Menu management',
                icon: <Menu className='mx-auto' />,
                iconHover: (
                    <Menu
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                baseUrl: '/menu',
                to: '/menu/catalogue',
            },
        ],
    },
    {
        group: [
            {
                name: 'Order rating',
                icon: <OrderRatings className='mx-auto' />,
                iconHover: (
                    <OrderRatings
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/order-rating/rating',
                baseUrl: '/order-rating',
            },
            {
                name: 'Customers information',
                icon: <Customers className='mx-auto' />,
                iconHover: (
                    <Customers
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/customer-information',
            },
            {
                name: 'Loyalty cashback',
                icon: <Cashback className='mx-auto' />,
                iconHover: (
                    <Cashback
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                iconActive: (
                    <Cashback
                        stroke='#6C5DD3'
                        className='mx-auto'
                    />
                ),
                to: '/loyalty-cashback/transactional-reward-points',
                baseUrl: '/loyalty-cashback',
            },
        ],
    },
    {
        group: [
            {
                name: 'Credits',
                icon: <Credit className='mx-auto' />,
                iconHover: (
                    <Credit
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/credits/infrastructure',
                baseUrl: '/credits',
            },
        ],
    },
    {
        group: [
            {
                name: 'Notifications',
                icon: <Notifications className='mx-auto' />,
                iconHover: (
                    <Notifications
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/notifications/notifications-list',
                baseUrl: '/notifications',
            },

            {
                name: 'Banners',
                icon: <Banners className='mx-auto' />,
                iconHover: (
                    <Banners
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/banners/banner-catalogue/primary-banner',
                baseUrl: '/banners',
            },
            {
                name: 'Media library',
                icon: <MediaLibrary className='mx-auto' />,
                iconHover: (
                    <MediaLibrary
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/media-library/grid',
                baseUrl: '/media-library',
            },

            {
                name: 'Offers',
                icon: <Offers className='mx-auto' />,
                iconHover: (
                    <Offers
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/offers/offer-list',
                baseUrl: '/offers',
            },
        ],
    },
    {
        group: [
            {
                name: 'Table management',
                icon: <TableManagement className='mx-auto' />,
                iconHover: (
                    <TableManagement
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/table-management/:section',
                baseUrl: '/table-management',
            },
            {
                name: 'Rider',
                icon: <Riders className='mx-auto' />,
                iconHover: (
                    <Riders
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/rider',
            },
        ],
    },
    {
        group: [
            {
                name: 'Reports',
                icon: <Reports className='mx-auto' />,
                iconHover: (
                    <Reports
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/reports',
            },

            {
                name: 'Abandoned carts',
                icon: <AbandonedCart className='mx-auto' />,
                iconHover: (
                    <AbandonedCart
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/abandoned-carts',
            },
        ],
    },
    {
        group: [
            {
                name: 'Select Restaurant',
                icon: <HelpDocumentation className='mx-auto' />,
                iconHover: (
                    <HelpDocumentation
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/select-restaurant',
                onClick: true,
            },
        ],
    },
    {
        group: [
            {
                name: 'Settings',
                icon: <Settings className='mx-auto' />,
                iconHover: (
                    <Settings
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/settings/user/user-list',
                baseUrl: '/settings',
            },
            {
                name: 'Device setting',
                icon: <Settings className='mx-auto' />,
                iconHover: (
                    <Settings
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/device-setting',
            },
        ],
    },
    {
        group: [
            {
                name: 'POSSettings',
                icon: <Settings className='mx-auto' />,
                iconHover: (
                    <Settings
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/POSSettings',
                // baseUrl: '/POSSettings',
            },
        ],
    },
    {
        group: [
            {
                name: 'AddWaiter',
                icon: <WaiterIcon className='mx-auto h-8' />,
                iconHover: (
                    <WaiterIcon
                        className='mx-auto h-8'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/add-waiter',
            },
            {
                name: 'Waiter',
                icon: <WaiterIcon className='h-8 mx-auto' />,
                iconHover: (
                    <WaiterIcon
                        className='mx-auto h-8'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/waiter',
            },
        ],
    },
    {
        group: [
            {
                name: 'Help documentation',
                icon: <HelpDocumentation className='mx-auto' />,
                iconHover: (
                    <HelpDocumentation
                        className='mx-auto'
                        stroke='#FAFAFA'
                    />
                ),
                to: '/help-documentation',
            },
        ],
    },
];
