import React, { useState } from 'react';
import { ReactComponent as Close } from '../../../Assets/close.svg';
import { ReactComponent as LeftArrow } from '../../../Assets/chevron-down.svg';
import { IsMobileScreen, IsLaptopLargeScreen } from '../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import OfferDetailsPopuploader from '../../../Components/ContentLoader/OfferDetailsPopuploader';

export default function CustomerOfferDetails(props) {
    const { handleClickOrderId, offerPopupDetails, selectedLangaugeId, isOfferDetailLoading } = props;

    const offerType = {
        flat_discount: 'Flat Discount',
        percentage_discount: 'Percentage Discount',
        free_delivery: 'Free Delivery',
        free_items: 'Get an item',
        buy_x_get_y: 'Buy X Get Y free',
        menu_discount: 'Menu Discount',
        buy_one_get_one_free: 'BOGO',
        coupled_offer: 'Coupled Offer',
        combo_item_price_deal: 'Value Deal',
        BOGO_View: 'BOGO View',
    };

    const [isShoRiderFeedBack, setIsShoRiderFeedBack] = useState(true);

    const [ShowHideText, setShowHideText] = useState('Show');

    const handleClickShowHide = () => {
        setIsShoRiderFeedBack(!isShoRiderFeedBack);
        setShowHideText(isShoRiderFeedBack ? 'Show' : 'Hide');
    };

    const [showOfferpage, setShowOfferPage] = useState(false);

    const handleClickSelectOffer = () => {
        isMobileScreen ? setShowCustmoerDetailsPopup(true) : setShowCustmoerDetailsPopup(false);
        setShowOfferPage(!showOfferpage);
    };

    const isMobileScreen = IsMobileScreen();
    const isLaptopLargeScreen = IsLaptopLargeScreen();
    const screenWidth = useWindowSize().width;
    const screenHeight = useWindowSize().height;

    const [showCustmoerDetailsPopup, setShowCustmoerDetailsPopup] = useState(true);

    return (
        <>
            {showCustmoerDetailsPopup && (
                <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center md:bg-white md:relative'>
                    <div className='max-w-[644px] w-full rounded-xl md:rounded-none bg-shades-50 pl-8 pr-[14px] py-6 md:px-4 md:py-4 m-auto md:max-w-full overflow-auto [&::-webkit-scrollbar]:hidden'>
                        {isOfferDetailLoading ? (
                            <OfferDetailsPopuploader />
                        ) : (
                            <>
                                {isMobileScreen && (
                                    <div
                                        className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                                        onClick={() => handleClickOrderId()}>
                                        <LeftArrow className='rotate-90' />
                                        <span className='ml-1'>Back to order ratings</span>
                                    </div>
                                )}

                                <div className='flex flex-row justify-between items-center mb-2 pb-4 border-b mr-[18px] md:mr-0 border-neutral-300 md:items-start'>
                                    <div>
                                        <span className='paragraph-large-medium md:paragraph-medium-medium mb-1'>
                                            Customer offer details
                                        </span>
                                    </div>
                                    <div
                                        className='md:hidden cursor-pointer'
                                        onClick={() => handleClickOrderId()}>
                                        <Close />
                                    </div>
                                </div>

                                <div>
                                    <div className='border-b border-neutral-300 pb-4 mb-4'>
                                        <h5 className='paragraph-medium-medium mb-2'>Heading 1</h5>
                                        <div className='mb-1.5 pt-1.5'>
                                            <span className='paragraph-medium-medium text-neutral-500'>
                                                Offer type:
                                            </span>
                                            {offerPopupDetails?.offer_type ? (
                                                <span className='paragraph-medium-regular ml-2'>
                                                    {offerType[offerPopupDetails?.offer_type]}
                                                </span>
                                            ) : (
                                                <span className='paragraph-medium-regular ml-2'>{'N/A'} </span>
                                            )}
                                        </div>
                                        <div className='mb-1.5 pt-1.5'>
                                            <span className='paragraph-medium-medium text-neutral-500'>
                                                Offer Title:
                                            </span>
                                            {offerPopupDetails?.translations?.title[selectedLangaugeId] ? (
                                                <span className='paragraph-medium-regular ml-2'>
                                                    {offerPopupDetails?.translations?.title[
                                                        selectedLangaugeId
                                                    ][0].toUpperCase() +
                                                        offerPopupDetails?.translations?.title[
                                                            selectedLangaugeId
                                                        ].slice(1)}
                                                </span>
                                            ) : (
                                                <span className='paragraph-medium-regular ml-2'>{'N/A'}</span>
                                            )}
                                        </div>
                                        <div className='mb-1.5 pt-1.5'>
                                            <span className='paragraph-medium-medium text-neutral-500'>
                                                Offer Description:
                                            </span>
                                            {offerPopupDetails?.translations?.description[selectedLangaugeId] ? (
                                                <span className='paragraph-medium-regular ml-2'>
                                                    {offerPopupDetails?.translations?.description[
                                                        selectedLangaugeId
                                                    ][0].toUpperCase() +
                                                        offerPopupDetails?.translations?.description[
                                                            selectedLangaugeId
                                                        ].slice(1)}
                                                </span>
                                            ) : (
                                                <span className='paragraph-medium-regular ml-2'>{'N/A'}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className='border-b border-neutral-300 pb-4 mb-4'>
                                        <h5 className='paragraph-medium-medium mb-2'>Heading 2</h5>
                                        <div className='mb-1.5 pt-1.5'>
                                            <span className='paragraph-medium-medium text-neutral-500'>
                                                Maximum discount:
                                            </span>
                                            {offerPopupDetails?.discount_amount ? (
                                                <span className='paragraph-medium-regular ml-2'>
                                                    ₹{offerPopupDetails?.max_discount_amount}
                                                </span>
                                            ) : (
                                                <span className='paragraph-medium-regular ml-2'>{'N/A'}</span>
                                            )}
                                        </div>
                                        <div className='mb-1.5 pt-1.5'>
                                            <span className='paragraph-medium-medium text-neutral-500'>
                                                Allowed usage:
                                            </span>
                                            {offerPopupDetails?.max_usage_count ? (
                                                <span className='paragraph-medium-regular ml-2'>
                                                    {offerPopupDetails?.max_usage_count} per customer
                                                </span>
                                            ) : (
                                                <span className='paragraph-medium-regular ml-2'>{'N/A'}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className='border-b border-neutral-300 pb-4 mb-4'>
                                        <h5 className='paragraph-medium-medium mb-2'>Heading 3</h5>
                                        <div className='mb-1.5 pt-1.5'>
                                            <span className='paragraph-medium-medium text-neutral-500'>
                                                Offer created at:
                                            </span>
                                            <span className='paragraph-medium-regular ml-2'>
                                                {offerPopupDetails?.created_at ?? 'N/A'}
                                            </span>
                                        </div>
                                        <div className='mb-1.5 pt-1.5'>
                                            <span className='paragraph-medium-medium text-neutral-500'>
                                                Offer used at:
                                            </span>
                                            <span className='paragraph-medium-regular ml-2'>
                                                {offerPopupDetails?.offer_used_at ?? 'N/A'}
                                            </span>
                                        </div>
                                        <div className='pt-1.5'>
                                            <span className='paragraph-medium-medium text-neutral-500'>
                                                Offer valid till:
                                            </span>
                                            <span className='paragraph-medium-regular ml-2'>
                                                {offerPopupDetails?.valid_till ?? 'N/A'}
                                            </span>
                                        </div>
                                    </div>

                                    <div>
                                        <h5 className='paragraph-medium-medium mb-2'>Customer details</h5>
                                        <div className='mb-1.5 pt-1.5'>
                                            <span className='paragraph-medium-medium text-neutral-500'>Name:</span>
                                            <span className='paragraph-medium-regular ml-2'>
                                                {offerPopupDetails?.customer?.name ?? 'N/A'}
                                            </span>
                                        </div>
                                        <div className='mb-1.5 pt-1.5'>
                                            <span className='paragraph-medium-medium text-neutral-500'>Number:</span>
                                            <span className='paragraph-medium-regular ml-2'>
                                                {offerPopupDetails?.customer?.mobile_number ?? 'N/A'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
