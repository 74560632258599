import React, { useState } from 'react';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { offerTypesActiveTab } from '../../Helper/constant';
import APIV5 from '../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import APIV2 from '../../../../api/axios/APIV2';
import { format, parseISO } from 'date-fns';

export default function Footer() {
    const { handleSubmit, watch, setError } = useFormContext();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const { section, id } = useParams();

    const isLastSection = section === 'notification-settings';

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const isEditOffer = watch('isEditOffer');

    const goNextSection = () => {
        const sections = offerTypesActiveTab[watch('offer_type')?.value];

        const currentIndex = sections.indexOf(section);
        if (currentIndex === -1 || currentIndex === sections.length - 1) {
            return null;
        }

        const nextSection = sections[currentIndex + 1];

        const editOfferRoute = `/offers/edit/${id}/${nextSection}`;

        const route = isEditOffer ? editOfferRoute : `/offers/create-offer/${nextSection}`;

        if (nextSection) {
            navigate(route);
        }
    };

    const formateNotificationData = (rowData) => {
        const transformed = {};

        transformed.title = {};

        rowData.notification_title.forEach((value, index) => {
            if (index !== 0) {
                transformed['title'][index] = value || '';
            }
        });

        transformed.description = {};

        rowData.notification_description.forEach((value, index) => {
            if (index !== 0) {
                transformed['description'][index] = value || '';
            }
        });

        transformed.image = {};

        rowData?.notification_image?.forEach((value, index) => {
            if (index !== 0 && value?.id) {
                transformed['image'][index] = value.id;
            }
        });

        return {
            time_to_send:
                rowData.notification_time_type?.value === 'instant'
                    ? null
                    : format(new Date(rowData.notification_selected_date), 'yyyy-MM-dd') +
                      ' ' +
                      rowData.notification_selected_time,
            translations: transformed,
            screen: rowData?.notification_screen?.value,
        };
    };

    const createNotification = async (formattedOfferData, rowData) => {
        const formattedNotificationData = formateNotificationData(rowData);

        try {
            const response = await APIV5.post(`restaurants/${selectedRestaurantId}/notifications`, {
                ...formattedNotificationData,
            });

            isEditOffer
                ? await editOffer({
                      ...formattedOfferData,
                      notification_id: watch('notification_id'),
                  })
                : await createOffer({
                      ...formattedOfferData,
                      notification_id: response.notification.id,
                  });
        } catch (error) {
            console.log(error);
        }
    };

    const editNotification = async (formattedOfferData, rowData) => {
        const formattedNotificationData = formateNotificationData(rowData);

        try {
            const response = await APIV5.put(
                `restaurants/${selectedRestaurantId}/notifications/${watch('notification_id')}`,
                { ...formattedNotificationData }
            );

            isEditOffer
                ? await editOffer({
                      ...formattedOfferData,
                      notification_id: watch('notification_id'),
                  })
                : await createOffer({
                      ...formattedOfferData,
                      notification_id: response.notification.id,
                  });
        } catch (error) {
            console.log(error);
        }
    };

    const createOffer = async (offerData) => {
        try {
            const response = await APIV2.post(`/restaurants/${selectedRestaurantId}/offers`, { ...offerData });

            setIsLoading(false);

            if (response.success) {
                navigate('/offers/offer-list');
            }
        } catch (error) {
            console.log('error : ', error);

            error?.response?.data?.errors?.percentage &&
                setError('percentage', { message: error?.response?.data?.errors?.percentage });

            error?.response?.data?.errors?.coupon_code &&
                setError('coupon_code', { message: error?.response?.data?.errors?.coupon_code });

            error?.response?.data?.errors?.free_items &&
                setError('free_items', { message: error?.response?.data?.errors?.free_items });

            error?.response?.data?.errors?.linked_categories &&
                setError('selected_item_category', { message: error?.response?.data?.errors?.linked_categories });

            setIsLoading(false);
        }
    };

    const editOffer = async (offerData) => {
        try {
            const response = await APIV2.put(`/restaurants/${selectedRestaurantId}/offers/${id}`, { ...offerData });

            setIsLoading(false);

            if (response.success) {
                navigate('/offers/offer-list');
            }
        } catch (error) {
            console.log('error : ', error);

            error?.response?.data?.errors?.percentage &&
                setError('percentage', { message: error?.response?.data?.errors?.percentage });

            error?.response?.data?.errors?.coupon_code &&
                setError('coupon_code', { message: error?.response?.data?.errors?.coupon_code });

            error?.response?.data?.errors?.free_items &&
                setError('free_items', { message: error?.response?.data?.errors?.free_items });

            error?.response?.data?.errors?.linked_categories &&
                setError('selected_item_category', { message: error?.response?.data?.errors?.linked_categories });

            setIsLoading(false);
        }
    };

    const formateOfferData = (rowData) => {
        const body = {};

        body.allow_offer_clubbing = rowData?.allow_offer_clubbing?.value ?? 0;
        body.offer_type = rowData?.offer_type?.value;
        body.promo_consolidation = rowData?.promo_consolidation?.value;
        body.auto_apply = rowData?.auto_apply?.value ?? 0;
        body.coupon_code = rowData?.coupon_code;
        body.display_in_cart_tab = rowData?.display_in_cart_tab?.value;
        body.display_in_offers_tab = rowData?.display_in_offers_tab?.value;
        body.min_cart_amount = rowData?.minimum_cart_amount_value;
        body.minimum_cart_amount_value = rowData?.minimum_cart_amount_value;
        body.max_usage_count = rowData?.max_usage_count;
        body.max_usage_count_per_user = rowData?.max_usage_count_per_user;
        body.repeat_usage_time = rowData?.repeat_usage_time;
        body.translations = transformTranslations(rowData.translations);
        body.discount_amount = rowData?.discount_amount;

        body.time_slot_setting = rowData?.offer_timing_timingType?.value ?? 'no_time_slots';
        body.percentage = rowData?.percentage;
        body.apply_on_addon = rowData?.apply_on_addon?.value;
        body.ordering_modes = transformOrderingModes(rowData.ordering_modes);
        body.buy_x1 = rowData?.buy_x_quantity;
        body.get_y1 = rowData?.get_y_quantity;
        body.customer_ids = rowData?.customer_ids?.map((el) => el?.id);
        body.max_discount_amount = rowData?.max_discount_amount ?? 0;
        body.validate_from = rowData?.has_offer_validity?.value
            ? format(parseISO(rowData?.validate_from), 'yyyy-MM-dd')
            : null;
        body.validate_to = rowData?.has_offer_validity?.value
            ? format(parseISO(rowData?.validate_to), 'yyyy-MM-dd')
            : null;
        body.perpetual_offer = rowData?.has_offer_validity?.value ? 'with_expiry' : 'with_out_expiry';
        body.hide_on_exhaustion = rowData?.hide_on_exhaustion;
        body.schedules = transformOfferTiming(rowData.offer_timing, rowData?.offer_timing_timingType?.value);
        body.required_items =
            rowData?.implementation_preset?.value !== 'specific_items'
                ? []
                : formateRequiredItems(rowData?.selected_item);
        body.catogaries = rowData?.implementation_preset?.value;
        body.categories =
            rowData?.implementation_preset?.value !== 'specific_categories'
                ? null
                : formateCategory(rowData?.selected_category);
        body.free_items = rowData?.free_items;
        body.min_required_order_counts = rowData?.hasMinMaxOrderCount?.value
            ? rowData?.min_required_order_counts
            : null;
        body.max_required_order_counts = rowData?.hasMinMaxOrderCount?.value
            ? rowData?.max_required_order_counts
            : null;
        body.min_cart_item_quantity = rowData?.buy_x_quantity ?? 0;
        body.quantity_of_free_item = rowData?.get_y_quantity ?? 0;

        body.delete_ordering_modes = rowData?.delete_ordering_modes ?? [];

        body.combo_deal_item_index = rowData?.combo_item_price_deal ?? null;

        if (rowData?.selected_item_category?.list) {
            body.linked_categories = formateSelectedItemCategory(rowData?.selected_item_category?.list);

            body.primary_category_id = rowData?.selected_item_category?.category?.category_id;
        }

        return body;
    };

    const formateSelectedItemCategory = (data) => {
        const formattedData = [];

        data?.forEach((el) => {
            if (el.selectedOption) {
                formattedData.push({
                    hasVaraint: !!el.variants.length ? 1 : 0,
                    module_id: el.item_id,
                    module_name: 'items',
                    name: el.internal_name,
                    secondary_category_id: el.selectedOption ? el.selectedOption.value.category_id : null,
                });
                return;
            }

            if (el.variants) {
                el.variants.forEach((variant) => {
                    if (variant.options) {
                        variant.options.forEach((option) => {
                            if (option.selectedOption) {
                                formattedData.push({
                                    module_id: option.id,
                                    module_name: 'variant',
                                    name: option.selectedOption.internal_name,
                                    secondary_category_id: option.selectedOption.category_id,
                                });
                            }
                        });
                    }
                });
            }
        });

        return formattedData;
    };

    const formateCategory = (category) => {
        return category
            ?.filter((el) => !!el?.category_id)
            ?.map((el) => ({
                isSubCategory: !!el.parent_id,
                label: el.label,
                quantity: 1,
                required_item_category_id: el.category_id,
            }));
    };

    const formateRequiredItems = (selected_item) => {
        return selected_item?.map((itemObj) => {
            const { item, option } = itemObj;
            return {
                label: item?.label,
                item_id: item?.item_id,
                quantity: item?.quantity,
                required_condition: option && option?.value === 'or' ? 'or' : 'and',
            };
        });
    };

    const transformTranslations = (translations) => {
        const transformed = {};

        Object.keys(translations)?.forEach?.((key) => {
            const values = translations[key];
            transformed[key] = {};

            values.forEach((value, index) => {
                if (index !== 0) {
                    transformed[key][index] = value || '';
                }
            });
        });

        return transformed;
    };

    const transformOfferTiming = (offerTiming, timeType) => {
        if (timeType === 'same_time_slot_for_all_days') {
            return [
                {
                    days_of_week: [1, 2, 3, 4, 5, 6, 7],
                    time_slots: offerTiming,
                },
            ];
        }

        if (timeType === 'different_time_slots_for_different_days') {
            return offerTiming
                .map((timeSlots, index) => {
                    if (!Array.isArray(timeSlots)) {
                        timeSlots = [timeSlots];
                    }

                    return timeSlots.length > 0
                        ? {
                              days_of_week: [index + 1],
                              time_slots: timeSlots ? timeSlots : [],
                          }
                        : null;
                })
                .filter((schedule) => schedule !== null);
        }
        return null;
    };

    const transformOrderingModes = (orderingModes) => {
        return orderingModes?.map((mode) => ({
            restaurant_ordering_mode_id: mode.value.id,
            restaurant_ordering_mode_name: mode.value.display_name,
        }));
    };

    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    const getError = (formData) => {
        let errors = [];

        if (formData.offer_type?.value === 'menu_discount') {
            // Validate fields specific to menu discount
            if (!formData.percentage) {
                setError('percentage', { message: 'Please enter discount percentage' });
                errors.push('Discount percentage is missing');
            }
        } else {
            // Validate fields for other offer types
            if (
                !formData?.coupon_code &&
                formData.offer_type?.value != 'coupled_offer' &&
                formData.offer_type?.value != 'buy_one_get_one_free'
            ) {
                setError('coupon_code', { message: 'Please enter coupon code' });
                errors.push('Coupon code is missing');
            }

            availableLanguages.forEach((el) => {
                if (!formData?.translations?.title?.[el.language_id]) {
                    setError(`translations.title.${el.language_id}`, {
                        message: `Please enter title in ${el.language_label}`,
                    });
                    errors.push(`Title in ${el.language_label} is missing`);
                }

                if (!formData?.translations?.description?.[el.language_id]) {
                    setError(`translations.description.${el.language_id}`, {
                        message: `Please enter description in ${el.language_label}`,
                    });
                    errors.push(`Description in ${el.language_label} is missing`);
                }
            });

            if (formData.offer_type?.value === 'combo_item_price_deal') {
                if (!formData.combo_item_price_deal) {
                    setError('combo_item_price_deal', { message: 'Please enter index' });
                    errors.push('Combo item price deal index is missing');
                }
            }
        }

        return errors;
    };

    const handleClickSave = (formData) => {
        if (isLastSection) {
            let error = getError(formData);

            if (error?.length > 0) {
                return;
            }

            setIsLoading(true);

            const formattedOfferData = formateOfferData(formData);

            if (!!watch('send_notification')) {
                watch('isNotificationAvailable')
                    ? editNotification(formattedOfferData, formData)
                    : createNotification(formattedOfferData, formData);
                return;
            }

            isEditOffer ? editOffer(formattedOfferData) : createOffer(formattedOfferData);

            return;
        }

        goNextSection();
    };

    return (
        <section>
            <div className='sticky md:fixed md:justify-center md:border-none md:py-0 md:pb-1 md:pt-2 md:shadow-dropShadow left-0 right-0 bottom-0 flex px-8 lg:px-4 flex-row justify-end w-full max-w-[1336px] mx-auto bg-white py-6 border-t border-neutral-300 md:z-[10]'>
                <div className='flex flex-row md:w-full'>
                    <div
                        className='min-w-[196px] mr-5 md:w-1/2 md:mr-[7.5px] md:min-w-0'
                        onClick={() => navigate('/offers/offer-list')}>
                        <LargeDestructiveButton label='Discard' />
                    </div>

                    <div
                        className='min-w-[196px] md:w-1/2 md:ml-[7.5px] md:min-w-0'
                        onClick={handleSubmit(handleClickSave)}>
                        <LargePrimaryButton
                            label={isLastSection ? 'Save' : 'Next'}
                            isDefault={false}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
