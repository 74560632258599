import React from 'react';
import { CheckBoxWithoutLabels } from '../../../../../Components/FormControl/FormControls';

const MediaLibraryCard = (props) => {
    const { item, isSelectionEnable, selectedMediaList, setSelectedMediaList, setShowUploadPopup } = props;

    const updateSelectedList = () => {
        const id = item.id;

        setSelectedMediaList((prevState) => {
            return prevState.includes(id) ? prevState.filter((item) => item !== id) : [...prevState, id];
        });
    };

    const isChecked = selectedMediaList.includes(item.id);

    const handleSelection = () => {
        isSelectionEnable ? updateSelectedList() : setShowUploadPopup(item.id);
    };

    const removedSnackCase = item?.image_type?.split('_')?.join(' ');

    const imageType = item?.image_type && removedSnackCase?.charAt(0)?.toUpperCase() + removedSnackCase?.slice(1);

    return (
        <>
            <div
                onClick={handleSelection}
                className={`h-full w-full px-2 pt-2 pb-3 border rounded-lg mobile:text-center cursor-pointer relative ${
                    isChecked ? 'bg-primary-100 border-primary-500' : 'border-neutral-300'
                }`}>
                <img
                    src={item.image_url}
                    alt=''
                    className='h-[180px] w-[180px] mb-1 lg:h-[144px] lg:w-[144px] md:w-[152px] md:h-[152px] mobile:mx-auto largeTablet:mx-auto'
                />
                <h3 className='paragraph-medium-medium mb-1'>{item.image_title}</h3>

                <p className='text-neutral-500 paragraph-medium-italic'>{imageType}</p>

                <p>
                    {item.tags.map((tagEl, index) => (index === item.tags.length - 1 ? `#${tagEl}` : `#${tagEl}, `))}{' '}
                </p>

                {isSelectionEnable && (
                    <div className='absolute top-4 left-4'>
                        <CheckBoxWithoutLabels
                            id={item.id}
                            isChecked={isChecked}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default MediaLibraryCard;
