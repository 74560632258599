import React from 'react';
import { ReactComponent as CashBackIcon } from '../../../../Assets/loyalty-cashback.svg';
import { format, parse } from 'date-fns';
import { TableBlankComponentView } from '../../../../Components/BlankComponent/TableBlankComponentView';

export default function TableSection(props) {
    const { orderDetails, handleClickCustomerName, setPopupOrderId } = props;

    const formattedDate = (dateString) => {
        const parsedDate = parse(dateString, 'dd-MMM-yyyy', new Date());
        return format(parsedDate, 'dd MMM yyyy');
    };

    return (
        <>
            <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border mt-4'>
                <table className='w-full break-words'>
                    <thead>
                        <tr className='bg-neutral-50 text-left paragraph-overline-small text-neutral-700 shadow-innerShadow h-11 justify-center'>
                            <th className='px-6 min-w-[140px] lg:min-w-[122px]'>ORDER ID</th>
                            <th className='px-6 min-w-[230px] lg:min-w-[185px]'>CUSTOMER NAME</th>
                            <th className='px-6 min-w-[230px] lg:min-w-[163px]'>ORDER DATE</th>
                            <th className='px-6 min-w-[220px] lg:min-w-[166px]'>ORDER AMOUNT</th>
                            <th className='px-6 min-w-[225px] lg:min-w-[200px]'>CASHBACK EARNED</th>
                            <th className='px-6 min-w-[225px] lg:min-w-[200px]'>CASHBACK REDEEMED</th>
                        </tr>
                    </thead>

                    <tbody>
                        {!!orderDetails.length ? (
                            orderDetails.map((el, index) => (
                                <tr
                                    className='paragraph-small-regular border-t first:border-none even:bg-neutral-50 border-neutral-300 h-[70px] justify-center'
                                    key={index}>
                                    <td
                                        className='px-6 cursor-pointer text-primary-500 hover:underline underline-offset-3'
                                        onClick={() => {
                                            setPopupOrderId(el.order_id);
                                        }}>
                                        {el.order_id}
                                    </td>

                                    <td
                                        className='px-6 cursor-pointer text-primary-500 hover:underline underline-offset-3'
                                        onClick={() => handleClickCustomerName(el.customer_id)}>
                                        {el?.customer_name}
                                    </td>

                                    <td className='px-6'>{el?.order_date ? formattedDate(el.order_date) : '-'}</td>

                                    <td className='px-6'>₹{el.order_amount}</td>
                                    {el.cashback_earned ? (
                                        <td className='px-6'>
                                            <div className='flex flex-row items-center'>
                                                <CashBackIcon />
                                                <span className='pl-1'>{el.cashback_earned}</span>
                                            </div>
                                        </td>
                                    ) : (
                                        <td className='px-6'>
                                            <div className='flex flex-row items-center'>
                                                <span className='pl-1'>---</span>
                                            </div>
                                        </td>
                                    )}
                                    {el.cashback_redeemed ? (
                                        <td className='px-6'>
                                            <div className='flex flex-row items-center'>
                                                <CashBackIcon />
                                                <span className='pl-1'>{el.cashback_redeemed}</span>
                                            </div>
                                        </td>
                                    ) : (
                                        <td className='px-6'>
                                            <div className='flex flex-row items-center'>
                                                <span className='pl-1'>---</span>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={6}>
                                    <TableBlankComponentView previewText={'No Content Available'} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}
