import React, { useEffect, useState } from 'react';
import CalenderField from '../../../Components/Calender/CalenderField';
import { ReactComponent as SendIcon } from '../../../Assets/send.svg';
import { ReactComponent as FilterIcon } from '../../../Assets/filter.svg';
import { ReactComponent as SelectIcon } from '../../../Assets/select.svg';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as Export } from '../../../Assets/export.svg';
import { useNavigate, useParams } from 'react-router-dom';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';
import { useSelector } from 'react-redux';
import APIV3 from '../../../api/axios/APIV3';
import { format } from 'date-fns';

const HeaderOrderDetails = (props) => {
    const { id } = useParams();
    const { handleClickSendOffer, setSelectedDates, selectedDates, setFilters, filters, setPaginationState } = props;

    const navigate = useNavigate();

    const handleBackFunctionality = () => {
        navigate('/loyalty-cashback');
    };

    const [showFilters, setShowFilters] = useState(false);
    const [showOrderDateFilter, setShowOrderDateFilter] = useState(false);
    const [showOrderTypeFilter, setShowOrderTypeFilter] = useState(false);
    const [showOrderStatusFilter, setShowOrderStatusFilter] = useState(false);
    const [showItemsOrderedFilter, setShowItemsOrderedFilter] = useState(false);
    const [showPaymentModeFilter, setShowPaymentModeFilter] = useState(false);

    const handleClickFilter = () => {
        setShowFilters(!showFilters);
        setShowOrderDateFilter(false);
        setShowOrderTypeFilter(false);
        setShowOrderStatusFilter(false);
        setShowItemsOrderedFilter(false);
        setShowPaymentModeFilter(false);
    };

    const [isExportDataDisable, setIsExportDataDisable] = useState(false);

    const orderingModes = useSelector((state) => state.outlet?.orderingModes);
    const [deliveryIds, setDeliveryIds] = useState([]);
    const [dineInIds, setDineInIds] = useState([]);
    const [takeawayIds, setTakeawayIds] = useState([]);

    useEffect(() => {
        const delivery_ids = orderingModes.filter((el) => el.display_name === 'Delivery').map((item) => item.id);
        const dine_in_ids = orderingModes
            .filter(
                (el) => el.display_name === 'Fine Dine' || el.display_name === 'Self Serve' || el.display_name === 'Qsr'
            )
            .map((item) => item.id);
        const takeaway_ids = orderingModes.filter((el) => el.display_name === 'Pickup').map((item) => item.id);

        setDeliveryIds(delivery_ids);
        setDineInIds(dine_in_ids);
        setTakeawayIds(takeaway_ids);
    }, [orderingModes, filters.primaryfilter]);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const initialFilterSelection = [
        { label: 'Order date', value: 'order_date' },
        { label: 'Order type', value: 'order_type' },
        { label: 'Order status', value: 'order_status' },
        { label: 'Revenue generated', value: 'revenue_generated' },
        { label: 'Payment mode', value: 'payment_method' },
    ];

    const orderTypeFilter = [
        { label: 'All', value: null },
        { label: 'Delivery', value: deliveryIds },
        { label: 'Dine-in', value: dineInIds },
        { label: 'Takeaway', value: takeawayIds },
    ];

    const orderStatusFilter = [
        { label: 'All', value: null },
        { label: 'Delivered', value: ['customer_picked_up', 'rider_delivered', 'delivered'] },
        { label: 'Cancelled', value: ['restaurant_cancelled', 'customer_cancelled'] },
    ];

    const itemsOrderedFilter = [
        { label: 'Highest first', value: 'desc' },
        { label: 'Lowest first', value: 'asc' },
    ];

    const orderDateFilter = [
        { label: 'Latest first', value: 'desc' },
        { label: 'Earliest first', value: 'asc' },
    ];

    const paymentModeFilter = [
        { label: 'All', value: null },
        { label: 'Net banking', value: 'NET_BANKING' },
        { label: 'UPI', value: 'UPI' },
        { label: 'UPI_INTENT', value: 'UPI_INTENT' },
        { label: 'Cash', value: 'CASH_ON_DELIVERY' },
        { label: 'Credit card', value: 'CREDIT_CARD' },
        { label: 'Debit card', value: 'DEBIT_CARD' },
        { label: 'Balance', value: 'BALANCE' },
        { label: 'Credit card/Debit card', value: 'CREDIT_CARD/DEBIT_CARD' },
        { label: 'Pay Later', value: 'PAY_LATTER' },
    ];

    // NET_BANKING,UPI,CASH_ON_DELIVERY,BALANCE,CREDIT_CARD,DEBIT_CARD

    const handleSelection = (selection) => {
        if (selection?.value === 'order_type') {
            setShowOrderTypeFilter(true);
            setShowOrderStatusFilter(false);
            setShowItemsOrderedFilter(false);
            setShowPaymentModeFilter(false);
            setShowOrderDateFilter(false);
        } else if (selection?.value === 'order_status') {
            setShowOrderStatusFilter(true);
            setShowOrderTypeFilter(false);
            setShowItemsOrderedFilter(false);
            setShowPaymentModeFilter(false);
            setShowOrderDateFilter(false);
        } else if (selection?.value === 'revenue_generated') {
            setShowItemsOrderedFilter(true);
            setShowOrderStatusFilter(false);
            setShowOrderTypeFilter(false);
            setShowPaymentModeFilter(false);
            setShowOrderDateFilter(false);
        } else if (selection?.value === 'payment_method') {
            setShowPaymentModeFilter(true);
            setShowItemsOrderedFilter(false);
            setShowOrderStatusFilter(false);
            setShowOrderTypeFilter(false);
            setShowOrderDateFilter(false);
        } else if (selection?.value === 'order_date') {
            setShowOrderDateFilter(true);
            setShowPaymentModeFilter(false);
            setShowItemsOrderedFilter(false);
            setShowOrderStatusFilter(false);
            setShowOrderTypeFilter(false);
        }
        setFilters((prevState) => ({
            ...prevState,
            primaryfilter: selection?.value,
        }));
    };

    const handleSelectionSecondary = (selection) => {
        if (filters.secondaryFilter == selection?.value) return;
        setFilters((prevState) => ({
            ...prevState,
            secondaryFilter: selection?.value,
        }));
        setPaginationState((prevState) => ({ ...prevState, selectedPage: 1 }));
    };

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    let data;

    const handleClickExportData = async () => {
        data = {
            type: 'customer_orders_list_report',
            customer_id: id,
            restaurant_id: selectedRestaurantId,
        };
        if (selectedDates) {
            data.start_date = formatDate(selectedDates[0]);
            data.end_date = formatDate(selectedDates[1]);
        }
        if (
            (filters.primaryfilter === 'order_date' || filters.primaryfilter === 'revenue_generated') &&
            filters.secondaryFilter
        ) {
            data.filter = { column: filters.primaryfilter, direction: filters.secondaryFilter };
        }

        if (filters.primaryfilter === 'order_status') {
            data.order_status = filters.secondaryFilter;
        }
        if (filters.primaryfilter === 'order_type') {
            data.ordering_mode_ids = filters.secondaryFilter;
        }
        if (filters.primaryfilter === 'payment_method') {
            data.payment_method = filters.secondaryFilter;
        }

        try {
            setIsExportDataDisable(true);
            const response = await APIV3.post(`export-report`, data);

            if (response.success === true) {
                setIsExportDataDisable(false);
            } else {
                setIsExportDataDisable(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className='flex flex-row justify-between md:block mb-6 md:mb-4'>
                <div className='flex flex-row md:justify-between'>
                    <div className='flex items-center'>
                        {/* <div onClick={() => handleBackFunctionality()}>
                            <LargePrimaryButton label='Go Back' />
                        </div> */}
                        {/* <div className='flex-grow'>
                            <CalenderField
                                setSelectedDates={setSelectedDates}
                                selectedDates={selectedDates}
                            />
                        </div> */}
                        {/* <div className='ml-4'>
                            <LargePrimaryButton
                                label='Bulk select'
                                hideLabel='md:hidden'
                                leftIconDefault={<SelectIcon stroke='#ffffff' />}
                                leftIconClick={<SelectIcon stroke='#C4BEED' />}
                            />
                        </div> */}
                    </div>
                </div>
                <div className='flex flex-row md:justify-between md:mt-4'>
                    <div
                        className='mx-4 xl:mx-2 md:ml-0 md:mr-2 md:min-w-[64px]'
                        onClick={handleClickFilter}>
                        <LargePrimaryButton
                            leftIconDefault={<FilterIcon fill='#ffffff' />}
                            leftIconClick={<FilterIcon fill='#C4BEED' />}
                            label='Filters'
                            hideLabel='lg:hidden'
                        />
                    </div>
                    {/* <div
                        className='md:w-1/2'
                        onClick={handleClickExportData}>
                        <LargePrimaryButton
                            label='Export data'
                            leftIconDefault={<Export stroke='#FFFFFF' />}
                            leftIconClick={<Export stroke='#C4BEED' />}
                            hideLabel='lg:hidden md:block'
                            isClicked={isExportDataDisable}
                        />
                    </div> */}
                    {/* <div
                        className='md:w-1/2 mobile:w-16 ml-4 xl:ml-2 md:ml-2 cursor-pointer'
                        onClick={handleClickSendOffer}>
                        <LargePrimaryButton
                            isDefault={false}
                            label='Send offer'
                            leftIconDefault={<SendIcon stroke='#FFFFFF' />}
                            leftIconClick={<SendIcon stroke='#C4BEED' />}
                            hideLabel='lg:hidden md:block mobile:hidden'
                        />
                    </div> */}
                </div>
            </div>
            <div className='flex flex-row justify-end sm:block'>
                {showOrderTypeFilter ? (
                    <div className='flex sm:block items-center justify-end mb-4 mr-3 md:mb-4 md:ml-0'>
                        <div className='flex mobile:block'>
                            <div className='w-[200px] sm:w-1/2 mobile:w-full mobile:mr-0 mobile:mb-3'>
                                <DropdownWithValueLabel
                                    menuItems={orderTypeFilter}
                                    placeholder='Select Type'
                                    setSelected={(selection) => handleSelectionSecondary(selection)}
                                    label='Filter options'
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
                {showOrderStatusFilter ? (
                    <div className='flex sm:block items-center justify-end mb-4 mr-3 md:mb-4 md:ml-0'>
                        <div className='flex mobile:block'>
                            <div className='w-[200px] sm:w-1/2 mobile:w-full mobile:mr-0 mobile:mb-3'>
                                <DropdownWithValueLabel
                                    menuItems={orderStatusFilter}
                                    placeholder='Select Type'
                                    setSelected={(selection) => handleSelectionSecondary(selection)}
                                    label='Filter options'
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
                {showItemsOrderedFilter ? (
                    <div className='flex sm:block items-center justify-end mb-4 mr-3 md:mb-4 md:ml-0'>
                        <div className='flex mobile:block'>
                            <div className='w-[200px] sm:w-1/2 mobile:w-full mobile:mr-0 mobile:mb-3'>
                                <DropdownWithValueLabel
                                    menuItems={itemsOrderedFilter}
                                    placeholder='Select Type'
                                    setSelected={(selection) => handleSelectionSecondary(selection)}
                                    label='Filter options'
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
                {showPaymentModeFilter ? (
                    <div className='flex sm:block items-center justify-end mb-4 mr-3 md:mb-4 md:ml-0'>
                        <div className='flex mobile:block'>
                            <div className='w-[200px] sm:w-1/2 mobile:w-full mobile:mr-0 mobile:mb-3'>
                                <DropdownWithValueLabel
                                    menuItems={paymentModeFilter}
                                    placeholder='Select Type'
                                    setSelected={(selection) => handleSelectionSecondary(selection)}
                                    label='Filter options'
                                    selectedItem={
                                        filters?.primaryfilter === 'payment_method'
                                            ? paymentModeFilter.find(
                                                  (item) => item.value === filters.secondaryFilter
                                              ) || paymentModeFilter[0]
                                            : undefined
                                    }
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
                {showOrderDateFilter ? (
                    <div className='flex sm:block items-center justify-end mb-4 mr-3 md:mb-4 md:ml-0'>
                        <div className='flex mobile:block'>
                            <div className='w-[200px] sm:w-1/2 mobile:w-full mobile:mr-0 mobile:mb-3'>
                                <DropdownWithValueLabel
                                    menuItems={orderDateFilter}
                                    placeholder='Select Type'
                                    setSelected={(selection) => handleSelectionSecondary(selection)}
                                    label='Filter options'
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
                {showFilters ? (
                    <div className='flex sm:block items-center justify-end mb-4 md:mb-4'>
                        <div className='flex flex-row'>
                            <div className='w-[200px] sm:w-1/2 mobile:w-full mobile:mr-0 mobile:mb-3'>
                                <DropdownWithValueLabel
                                    menuItems={initialFilterSelection}
                                    placeholder='Select Type'
                                    setSelected={(selection) => handleSelection(selection)}
                                    label='Apply filter on'
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default HeaderOrderDetails;
