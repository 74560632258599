import React, { useEffect, useState } from 'react';
import { Table } from './Table';
import { TableListView } from './ListView';

import garlicBread from '../../../../Assets/garlic-bread.svg';
import baverages from '../../../../Assets/beverages.svg';
import classicPizza from '../../../../Assets/classic-pizza.svg';
import { IsMobileScreen } from '../../../../Constants/Constants';
import APIV5 from '../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';

export default function FavoriteCategory({ selectedDates, outletId, defaultParams }) {
    const header = ['FAVORITE CATEGORY', 'TOTAL ORDERS', 'REVENUE  GENERATED'];

    const style = ['min-w-[239px] lg:w-[238px]', 'min-w-[184px] lg:w-[208px]', 'min-w-[201px] lg:w-[218px]'];

    const [favoriteFoodCategoryDetails, SetFavoriteFoodCategoryDetails] = useState([]);
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const FavoriteFoodData = async () => {
        const defaultParamsData = defaultParams();
        try {
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}/favorite-categories`, {
                params: {
                    ...defaultParamsData,
                    outlet_id: [outletId],
                },
            });
            SetFavoriteFoodCategoryDetails(response);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        FavoriteFoodData();
    }, [selectedDates]);

    const tableDetails = (favoriteFoodCategoryDetails.favorite_categories || []).map((item) => [
        { icon: item.image },
        item.translations.title?.[1] || 'N/A',
        item.total_orders || 0,
        item.revenue_generated || '₹0',
    ]);
    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='w-full'>
                <div className='mb-4 md:mb-2'>
                    <span className='paragraph-large-medium text-black'>Favorite category</span>
                </div>

                {!isMobileScreen && (favoriteFoodCategoryDetails.favorite_categories || []).length > 0 ? (
                    <Table
                        header={header}
                        style={style}
                        details={tableDetails}
                    />
                ) : (
                    <div className='w-full border h-64 border-neutral-300 rounded-lg overflow-auto [&::-webkit-scrollbar]:hidden'>
                        <table className='w-full break-words'>
                            <thead>
                                <tr className='bg-neutral-50 paragraph-overline-small text-neutral-700 shadow-innerShadow h-11 justify-center'>
                                    {header.map((el, index) => (
                                        <th
                                            className={`text-left pageContent:min-w-min pl-6 ${style[index]}`}
                                            key={index}>
                                            {el}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                        </table>
                        <tbody className='flex justify-center items-center h-full'>
                            <tr className='first:border-none border-t border-neutral-300 even:bg-neutral-50 paragraph-small-regular h-[70px] justify-center'>
                                <td className='p-0'>
                                    <div className='w-full pl-6 flex flex-row items-center'>
                                        <span className='text-lg  font-bold'>No Content Available</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </div>
                )}
            </div>
        </>
    );
}
