import React, { useEffect, useState } from 'react';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as SearchIcon } from '../../../Assets/search.svg';
import { ReactComponent as AddIcon } from '../../../Assets/add.svg';
import { useSelector } from 'react-redux';
import APIV5 from '../../../api/axios/APIV5';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';
import { debounce } from 'lodash';

export default function Header(props) {
    const { setShowUploadPopup, fetchMedia, setPaginationState, bannerType, fetchImageSubCategory, typeSubCategory } =
        props;
    const [imageTypes, setImageTypes] = useState([]);

    const [appliedFilter, setAppliedFilter] = useState({
        imageType: { label: 'All', value: null },
        imageTag: { label: 'All', value: null },
    });

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const fetchImageTypes = async () => {
        try {
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}/image-types`);
            const type = response.image_types.map((el) => {
                const removedSnackCase = el.split('_').join(' ');
                return { label: removedSnackCase.charAt(0).toUpperCase() + removedSnackCase.slice(1), value: el };
            });

            setImageTypes(type);
            bannerType &&
                setAppliedFilter({ ...appliedFilter, imageType: type.find((item) => item.value == bannerType) });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchImageTypes();
        fetchImageSubCategory();
    }, []);

    const handleSelectSubCategory = (selection) => {
        props.setAppliedFilter((prevState) => ({ ...prevState, tag_id: selection.value }));
        setAppliedFilter((prevState) => ({ ...prevState, tagId: selection }));
        setPaginationState((prevState) => ({ ...prevState, selectedPageNumber: 1 }));
        fetchMedia(null, 1, { tag_id: selection.value });
    };

    const handleSelectCategory = (selection) => {
        props.setAppliedFilter((prevState) => ({ ...prevState, image_type: selection.value }));
        setAppliedFilter((prevState) => ({ ...prevState, imageType: selection }));
        fetchMedia(null, 1, { image_type: selection.value });
        setPaginationState((prevState) => ({ ...prevState, selectedPageNumber: 1 }));
    };

    const debouncedSearch = debounce((value) => {
        props.setAppliedFilter((prevState) => ({ ...prevState, search: value }));
        fetchMedia(null, 1, { search: value });
    }, 1000);

    const handleSearch = (value) => {
        debouncedSearch(value);
    };

    return (
        <>
            <div className='flex flex-row w-full justify-between pb-4 border-b mb-4 border-neutral-300 md:border-b-0 md:pb-0 relative md:block'>
                <div className='md:flex md:justify-between'>
                    <div className='min-w-[375px] w-full mr-2 xl:min-w-[315px] largeTablet:min-w-[150px] lg:mr-1 md:w-full md:min-w-fit md:mr-0'>
                        <DefaultInputField
                            placeholder='Search media'
                            placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                            shadow='shadow-xSmall'
                            enteredValue={handleSearch}
                        />
                    </div>

                    <div
                        onClick={() => setShowUploadPopup(true)}
                        className='ml-4 max-w-[154px] w-full lg:ml-2 lg:max-w-[64px] cursor-pointer absolute right-0 top-0 md:relative'>
                        <LargePrimaryButton
                            isDefault={false}
                            label='Add image'
                            hideLabel='lg:hidden'
                            leftIconDefault={<AddIcon />}
                            leftIconClick={<AddIcon stroke='#C4BEED' />}
                        />
                    </div>
                </div>

                <div className='w-full md:mt-4'>
                    <div className='flex flex-row'>
                        <div className='max-w-[213px] w-full mx-2 relative xl:max-w-[185px] md:max-w-full md:ml-0 mobile:mr-1'>
                            <DropdownWithValueLabel
                                menuItems={[{ label: 'All', value: null }, ...imageTypes]}
                                shadow='shadow-xSmall'
                                selectedItem={appliedFilter.imageType}
                                setSelected={handleSelectCategory}
                                disabled={!!bannerType && bannerType !== 'grid' && bannerType !== 'list'}
                                fixedHeight='h-[250px]'
                            />
                        </div>

                        <div className='max-w-[213px] w-full ml-2 relative xl:max-w-[185px] md:max-w-full mobile:ml-1'>
                            <DropdownWithValueLabel
                                menuItems={[{ label: 'All', value: null }, ...typeSubCategory]}
                                itemsStyle='text-neutral-500'
                                selectedItem={appliedFilter.imageTag}
                                setSelected={handleSelectSubCategory}
                                fixedHeight='h-[250px]'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
