import React from 'react';
import { LargePrimaryButton } from '../Buttons/Button';
import CircleLoader from '../CircleLoader/CircleLoader';

export default function SaveButtonContainer(props) {
    const { handleClickSave, handleClickClose, isShowSaveLoader, isEditing, isDirty } = props;

    return (
        <>
            <div
                className={`w-full lg:mt-6 md:mt-4 lg:max-w-[289px] md:max-w-full ${
                    !isEditing && 'border-t border-neutral-300 pt-6'
                }`}>
                <div
                    className={`flex flex-row items-center ${
                        !isEditing && 'lg:flex-col-reverse'
                    } md:fixed md:bottom-0 md:left-0 md:right-0 md:bg-white md:shadow-dropShadow md:flex-row md:pb-1 md:pt-2 md:px-2`}>
                    <button
                        className={
                            isEditing
                                ? 'paragraph-medium-medium w-1/2 justify-center h-12 border rounded-md border-neutral-300 mr-1 lg:max-w-1/4 md:max-w-full md:mr-1 min-w-[183px] md:min-w-0 lg:min-w-[100px]'
                                : 'paragraph-medium-medium w-1/2 justify-center h-12 border rounded-md border-neutral-300 mr-1 lg:w-full lg:mr-0 md:mr-1 min-w-[183px] md:min-w-0'
                        }
                        onClick={handleClickClose}>
                        Cancel
                    </button>
                    <div
                        className={
                            isEditing
                                ? 'w-1/2 ml-1 lg:w-1/4 lg:ml-0 md:mb-0 md:ml-1 min-w-[183px] lg:min-w-[130px] md:min-w-0 md:w-1/2'
                                : 'w-1/2 ml-1 lg:w-full lg:ml-0 lg:mb-5 md:mb-0 md:ml-1 min-w-[183px] md:min-w-0'
                        }
                        onClick={isDirty ? handleClickSave : {}}>
                        <LargePrimaryButton
                            label={
                                <div className='flex gap-3 flex-row'>
                                    <span>Save image</span>
                                    {isShowSaveLoader && (
                                        <CircleLoader
                                            isLoading={true}
                                            isResponseSuccess={false}
                                        />
                                    )}
                                </div>
                            }
                            disabled={!isDirty}
                            isDefault={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
