import configureStore from '../../../reduxStore/index';

const store = configureStore.getState();

const availableLanguages = store.langauge;

// const getLanguageWiseField = (field) =>
//     availableLanguages.map((el) => ({ name: `translations.${field}.${el.language_id}`, valueType: 'string' }));

export const components = [
    {
        availableLanguages: availableLanguages,
        tab: 'offer-type',
        fields: [{ name: 'offer_type', valueType: 'object' }],
    },
    {
        tab: 'basic-details',
        fields: [
            // { name: 'hide_on_exhaustion', valueType: 'boolean' }, not compulsory
            { name: 'coupon_code', valueType: 'string' },
            // ...getLanguageWiseField('title'),
            // ...getLanguageWiseField('description'),
            // title remains
            // description remains
        ],
    },
    {
        tab: 'configure',
        fields: [
            { name: 'discount_amount', valueType: 'string' },
            { name: 'percentage', valueType: 'number' },

            // AddonAndMaximumDiscount
            { name: 'apply_on_addon', valueType: 'object' },
            { name: 'apply_on_addon', valueType: 'object' },
            { name: 'do_add_maximum_discount', valueType: 'object' },
            { name: 'max_discount_amount', valueType: 'number' },

            { name: 'combo_item_price_deal', valueType: 'number' },

            //FreeItemSection
            { name: 'free_items', valueType: 'object' },
            // free_items.${index}.quantity remain

            // BuyXGetYSection
            { name: 'buy_x_quantity', valueType: 'number' },
            { name: 'get_y_quantity', valueType: 'number' },

            { name: 'repeat_usage_time', valueType: 'number' },
            { name: 'is_minimum_order_count_enable', valueType: 'object' },
            { name: 'minimum_cart_amount_value', valueType: 'number' },
            { name: 'implementation_preset', valueType: 'object' },

            // DiseCategorySelection
            //  --> SelectCategorySection

            { name: 'selected_category', valueType: 'object' },

            // --> SelectDishesSection
            // -->  --> SingleItemSection
        ],
    },
    {
        tab: 'clubbing',
        fields: [
            { name: 'allow_offer_clubbing', valueType: 'object' },
            { name: 'auto_apply', valueType: 'object' },
            { name: 'promo_consolidation', valueType: 'object' },
        ],
    },
    {
        tab: 'visibility-linking',
        fields: [
            { name: 'display_in_cart_tab', valueType: 'object' },
            { name: 'display_in_offers_tab', valueType: 'object' },

            // UserSection
            { name: 'do_add_user', valueType: 'object' },
            { name: 'customer_ids', valueType: 'object' },
        ],
    },
    {
        tab: 'validity-applicability',
        fields: [
            // CouponLimit
            { name: 'has_coupon_limit', valueType: 'object' },
            { name: 'max_usage_count', valueType: 'number' },

            // Usage"number"
            { name: 'has_usage_"number"', valueType: 'object' },
            { name: 'max_usage_count_per_user', valueType: 'number' },

            // OrderCount
            { name: 'min_required_order_counts', valueType: 'number' },
            { name: 'max_required_order_counts', valueType: 'number' },

            // OrderingModeSection
            // { name: 'ordering_modes', valueType: "object" }, --> need to reconfigure

            // DatePickerSection
            { name: 'has_offer_validity', valueType: 'object' },
            { name: 'validate_from', valueType: 'string' },
            { name: 'validate_to', valueType: 'string' },
        ],
    },
    {
        tab: 'offer-timing',
        fields: [],
    },
    {
        tab: 'terms-conditions',
        fields: [],
    },
    {
        tab: 'item-list',
        fields: [{ name: 'selected_item_category', valueType: 'string' }],
    },
    {
        tab: 'notification-settings',
        fields: [
            { name: 'send_notification', valueType: 'boolean' },
            // for title, description and image fields do it
            { name: 'notification_screen', valueType: 'object' },

            // ScheduleNotification
            // make it
        ],
    },
];

export const tabEnableBasedOnOfferType = {};

export const offerTypesActiveTab = {
    flat_discount: [
        'offer-type',
        'basic-details',
        'configure',
        'clubbing',
        'visibility-linking',
        'validity-applicability',
        'offer-timing',
        'terms-conditions',
        'notification-settings',
    ],
    percentage_discount: [
        'offer-type',
        'basic-details',
        'configure',
        'clubbing',
        'visibility-linking',
        'validity-applicability',
        'offer-timing',
        'terms-conditions',
        'notification-settings',
    ],
    free_delivery: [
        'offer-type',
        'basic-details',
        'configure',
        'clubbing',
        'visibility-linking',
        'validity-applicability',
        'offer-timing',
        'terms-conditions',
        'notification-settings',
    ],
    free_items: [
        'offer-type',
        'basic-details',
        'configure',
        'clubbing',
        'visibility-linking',
        'validity-applicability',
        'offer-timing',
        'terms-conditions',
        'notification-settings',
    ],
    buy_x_get_y: [
        'offer-type',
        'basic-details',
        'configure',
        'clubbing',
        'visibility-linking',
        'validity-applicability',
        'offer-timing',
        'terms-conditions',
        'notification-settings',
    ],
    menu_discount: [
        'offer-type',
        'configure',
        'validity-applicability',
        'offer-timing',
        'terms-conditions',
        'notification-settings',
    ],
    buy_one_get_one_free: [
        'offer-type',
        'basic-details',
        'configure',
        'clubbing',
        'visibility-linking',
        'validity-applicability',
        'offer-timing',
        'terms-conditions',
        'notification-settings',
    ],
    coupled_offer: ['offer-type', 'basic-details', 'item-list', 'notification-settings'],
    combo_item_price_deal: [
        'offer-type',
        'basic-details',
        'configure',
        'clubbing',
        'visibility-linking',
        'validity-applicability',
        'offer-timing',
        'terms-conditions',
        'notification-settings',
    ],
    BOGO_View: [
        'offer-type',
        'basic-details',
        'configure',
        'clubbing',
        'visibility-linking',
        'validity-applicability',
        'offer-timing',
        'terms-conditions',
        'notification-settings',
    ],
};
