import React from 'react';
import { offerTypes } from '../../Components/Constants';
import DropdownSection from '../../../../Components/DropDown/DropdownSection';
import { useFormContext } from 'react-hook-form';

export default function OfferType() {
    const { reset, getValues } = useFormContext();
    return (
        <>
            <DropdownSection
                name='offer_type'
                menuItems={offerTypes}
                placeholder='Select type of offer'
                title='Offer type'
                description='Select the type of offer you would like to create for your customers.'
                rules={{ required: 'Please select offer type' }}
                zIndex='z-0'
                handleSelection={() => {
                    const currentOfferType = getValues('offer_type');
                    reset({
                        offer_type: currentOfferType,
                    });
                }}
            />
        </>
    );
}
