import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { IsMobileScreen } from '../../../Constants/Constants';
import Slider from 'react-slick';
import useOutsideClick from '../../../Helper/Hooks/useOutsideClick';
import { useSelector } from 'react-redux';
import APIV5 from '../../../api/axios/APIV5';

export default function ComplaintImagePopup(props) {
    const { handleClickClose } = props;

    const isMobileScreen = IsMobileScreen();
    const [orderPendingDetails, setOrderPendingDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const sliderRef = useRef(null);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const fetchImages = async () => {
        setIsLoading(true);
        try {
            const response = await APIV5.get(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/complaints`,
                {
                    params: {
                        per_page: 10,
                        status: ['pending'],
                    },
                }
            );
            setOrderPendingDetails(response?.complaints);
            setIsLoading(false);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);

    const popupRef = useRef();

    useOutsideClick(popupRef, handleClickClose);

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center overflow-auto md:bg-white md:relative p-4'>
                <div
                    className='bg-white w-[652px] md:w-full rounded-xl px-8 py-6 m-auto md:p-0'
                    ref={popupRef}>
                    {isMobileScreen ? (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='paragraph-medium-medium pl-1'>Back to order rating</span>
                        </div>
                    ) : (
                        <div className='flex flex-row justify-between items-center mb-4'>
                            <div className='paragraph-large-medium'>Complaint images</div>

                            <div
                                className='cursor-pointer'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </div>
                        </div>
                    )}

                    <div className='-mx-1'>
                        <div className='relative w-full'>
                            <Slider
                                ref={sliderRef}
                                {...settings}
                                className='image-slider'>
                                {orderPendingDetails?.data &&
                                    orderPendingDetails?.data?.length > 0 &&
                                    orderPendingDetails?.data?.map((complaint) =>
                                        complaint?.images?.map((image, imageIndex) => (
                                            <div
                                                key={imageIndex}
                                                className='flex justify-center items-center px-1'>
                                                <img
                                                    src={image?.url}
                                                    alt={`Complaint image ${imageIndex + 1}`}
                                                    className='rounded-lg mb-4 object-cover w-full h-[500px]'
                                                />
                                            </div>
                                        ))
                                    )}
                            </Slider>
                            <button
                                className='left-2.5 absolute -translate-y-2/4 cursor-pointer z-10 p-2 rounded-lg opacity-50 top-2/4 bg-neutral-300'
                                onClick={() => sliderRef.current.slickPrev()}>
                                <LeftArrowIcon
                                    className='rotate-90'
                                    fill='#000'
                                />
                            </button>
                            <button
                                className='next-button right-2.5 absolute -translate-y-2/4 p-2 rounded-lg opacity-50 top-2/4 bg-neutral-300'
                                onClick={() => sliderRef.current.slickNext()}>
                                <LeftArrowIcon className='-rotate-90' />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'>
            <div className='bg-white rounded-lg overflow-hidden shadow-xl max-w-2xl w-full relative'>
                <div className='flex justify-end p-4'>
                    <button
                        className='text-gray-500 hover:text-gray-700'
                        onClick={onClose}>
                        <CloseIcon />
                    </button>
                </div>
                <div className='p-4 flex flex-col items-center'>
                    <div className='relative w-full'>
                        <Slider
                            ref={sliderRef}
                            {...settings}
                            className='image-slider'>
                            {images.map((image, index) => (
                                <div
                                    key={index}
                                    className='slide px-4'>
                                    <img
                                        src={image}
                                        alt={`Complaint image ${index + 1}`}
                                        className='w-full h-auto rounded-lg mb-4 object-cover'
                                    />
                                </div>
                            ))}
                        </Slider>
                        <button
                            className='prev-button'
                            onClick={() => sliderRef.current.slickPrev()}>
                            Previous
                        </button>
                        <button
                            className='next-button'
                            onClick={() => sliderRef.current.slickNext()}>
                            Next
                        </button>
                    </div>
                </div>
            </div>
            </div> */}
        </>
    );
}
