import React, { useEffect, useState } from 'react';
import { IsMobileScreen } from '../../../Constants/Constants';
import { ReactComponent as LeftArrowIcon } from '../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { LargePrimaryButton, LargeTertiaryButton } from '../../../Components/Buttons/Button';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { RadioButton } from '../../../Components/FormControl/FormControls';
import { ErrorMessage } from '../../../Components/Error/ErrorMessage';
import APIV5 from '../../../api/axios/APIV5';
import { useSelector } from 'react-redux';

export default function RefundForComplaintPopup(props) {
    const { handleClickClose } = props;

    const isMobileScreen = IsMobileScreen();

    const [editComplaintDetails, setEditComplaintDetails] = useState([]);
    const [selectedRefundOption, setSelectedRefundOption] = useState(0);
    const [error, setError] = useState(null);
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const [customAmount, setCustomAmount] = useState(50);

    const complaintID = 2;
    const fetchOrderResolvedDetail = async () => {
        try {
            const response = await APIV5.get(`/restaurants/${selectedRestaurantId}/complaints/${complaintID}`);
            setEditComplaintDetails(response);
        } catch (error) {
            console.log('error ===> ', error);
        }
    };

    useEffect(() => {
        fetchOrderResolvedDetail();
    }, []);

    const discountType = [
        {
            id: 1,
            amount: 124,
            refund_type: 'Full refund',
        },
        {
            id: 2,
            amount: 183,
            refund_type: 'Partial refund',
        },
    ];

    const minAmount = 50;

    // const convertToCurrency = (price) =>
    //     price.toLocaleString('en-IN', {
    //         style: 'currency',
    //         currency: 'INR',
    //         minimumFractionDigits: 2,
    //     });

    const onChangeRefund = (value) => {
        setCustomAmount(value);
        setError(value < minAmount);
    };

    const handleRefund = async () => {
        try {
            let requestData = {
                status: selectedRefundOption === 0 ? 'approved' : 'reject',
                refund_amount:
                    selectedRefundOption === 0
                        ? editComplaintDetails?.refund_amount
                        : selectedRefundOption === 1
                          ? 183
                          : customAmount,
            };

            // Add complaint_reject_reason only for partial refund or custom amount
            if (selectedRefundOption !== 0) {
                requestData.complaint_reject_reason = 'Partial refund requested';
            }

            // Don't proceed if there's an error with custom amount
            if (selectedRefundOption === 2 && error) {
                return;
            }

            const response = await APIV5.put(
                `/restaurants/${selectedRestaurantId}/complaints/${complaintID}`,
                requestData
            );

            if (response?.success) {
                handleClickClose();
                // You might want to add a success notification here
            }
        } catch (error) {
            console.log('error ===> ', error);
            // You might want to add an error notification here
        }
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center overflow-auto md:bg-white md:relative p-4'>
                <div className='bg-white w-[500px] md:w-full rounded-xl px-8 py-6 m-auto md:p-0'>
                    {isMobileScreen ? (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='paragraph-medium-medium pl-1'>Back to order rating</span>
                        </div>
                    ) : (
                        <div className='flex flex-row justify-between items-center mb-4 border-b border-neutral-300 pb-4'>
                            <div className=''>
                                <div className='paragraph-large-medium'>Refund for complaint</div>
                                <div className='paragraph-medium-italic text-neutral-500'>tagline</div>
                            </div>

                            <div
                                className='cursor-pointer'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </div>
                        </div>
                    )}

                    {discountType?.map((el, index) => (
                        <div
                            className='flex flex-row items-center justify-between w-full'
                            key={index}>
                            <RadioButton
                                marginB='3'
                                label={el.refund_type}
                                optionId={index}
                                handleOptionChange={() => setSelectedRefundOption(index)}
                                checked={index === selectedRefundOption}
                            />

                            {/* <span className='paragraph-small-regular'>{convertToCurrency(el.amount)}</span> */}
                        </div>
                    ))}

                    <RadioButton
                        marginB='3'
                        label='Custom amount'
                        optionId={'custom'}
                        handleOptionChange={() => setSelectedRefundOption(2)}
                        checked={2 === selectedRefundOption}
                    />

                    {selectedRefundOption === 2 && (
                        <>
                            <DefaultInputField
                                label='Enter refund amount'
                                placeholder='Please enter refund amount'
                                inputType='number'
                                value={minAmount}
                                enteredValue={onChangeRefund}
                            />

                            {error && (
                                <ErrorMessage
                                    errors={{ message: `Please enter value greater or equal to ${minAmount}` }}
                                />
                            )}
                        </>
                    )}

                    <div className='flex flex-row items-center gap-4 mt-9'>
                        <div className='w-1/2'>
                            <span className='paragraph-large-medium'>Step 2/2</span>
                        </div>

                        <div className='flex flex-row w-1/2 gap-3'>
                            <div
                                className='w-full'
                                onClick={() => {
                                    handleClickClose();
                                }}>
                                <LargeTertiaryButton label='Cancel' />
                            </div>

                            <div className='w-full'>
                                <LargePrimaryButton
                                    label='Refund'
                                    onClick={handleRefund}
                                    disabled={selectedRefundOption === 2 && error}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
