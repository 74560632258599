import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as LeftArrow } from '../../Assets/chevron-down.svg';
import { ReactComponent as Close } from '../..//Assets/close.svg';
import { DefaultInputField, InputArea } from '../../Components/InputField/InputField';
import { LargeDestructiveButton, LargePrimaryButton, LargeTertiaryButton } from '../../Components/Buttons/Button';
import { IsMobileScreen } from '../../Constants/Constants';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '../../Components/Error/ErrorMessage';
import { InputField } from '../Login/Components/Components';
import PhoneInputDropdown from '../../Components/MobileNumberInputFlagDropDown/Dropdown';
import { useSelector } from 'react-redux';
import { countryCodes } from '../../Components/MobileNumberInputFlagDropDown/CountryData';
import APIV5 from '../../api/axios/APIV5';
import WaiterPopupLoader from './Components/WaiterPopupLoader';
import useOutsideClick from '../../Helper/Hooks/useOutsideClick';
import { ValidateUserPhoneNumber } from './Components/ValidateUserPhoneNumber';
import DropdonwnDifferentFirstMenuItem from '../../Components/DropDown/DropdonwnDifferentFirstMenuItem';
import APIV1 from '../../api/axios/APIV1';
import { id } from 'date-fns/locale';

export default function AddWaiter(props) {
    const isMobileScreen = IsMobileScreen();

    const {
        handleClickAddRider,
        riderDetails,
        setShowWaiterPage,
        addOrEdit,
        isEditUser,
        uploadedImageDetails,
        setriderSaved,
        riderDetailsEdit,
        isLoadingEditRider,
    } = props;

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
        getValues,
        setError,
        clearErrors,
        trigger,
    } = useForm();

    const popupRef = useRef();
    useOutsideClick(popupRef, handleClickAddRider);

    const [storeSubSections, setStoreSubSections] = useState([]);
    const [sections, setSections] = useState([]);
    const [isShowMobileNumberPopup, setIsShowMobileNumberPopup] = useState(false);
    const [validatedNumber, mobileNumber, otp] = watch(['validatedNumber', 'mobileNumber', 'otp']);
    const [isValidInput, setIsValidInput] = useState(true);
    const [countryData, setCountryData] = useState(
        countryCodes.find((country) => '+' + country.code == (watch('country_code') ?? '+91'))
    );
    const SubSectionFilter = storeSubSections.map((item) => ({
        label: item.internal_name,
        value: item.id,
    }));
    useEffect(() => {
        if (riderDetailsEdit && addOrEdit === 'edit') {
            setValue('validatedNumber', riderDetailsEdit?.mobile_number);
            setValue('Name', riderDetailsEdit?.first_name);
            setValue('mobile_number_id', riderDetailsEdit?.phone_detail_id);
            setValue('Email', riderDetailsEdit?.email);
        }
    }, [riderDetailsEdit, setValue, addOrEdit]);

    const addRiderCardInputField = [
        { label: 'Name', placeholder: 'Enter Name', required: true },
        { label: 'Mobile number', placeholder: 'Enter mobile number', required: true },
        { label: 'Email', placeholder: 'Enter email', required: true },
        { label: `Select Waiter's Section`, placeholder: "Select Waiter's Section", required: true },
        { label: "Select Waiter's Sub Section", placeholder: "Select Waiter's Sub Section", required: true },
        { label: "Select Waiter's Tables", placeholder: "Select Waiter's Tables", required: true },
    ];

    const variableSections = getValues('outletSelection') ?? [];
    const variableForSubSection = getValues('subSection') ?? [];
    const variableWaiters = getValues('TablesWaiter') ?? [];
    const outletSelection = watch('outletSelection') ?? [];
    const subSection = watch('subSection') ?? [];
    const Waiters = watch('TablesWaiter') ?? [];

    const handleClickItem = (item) => {
        const itemsToAdd = Array.isArray(item.sub_section) ? item.sub_section : [item.sub_section];
        setStoreSubSections((prevSections) => [...prevSections, ...itemsToAdd]);
        const selectedIndex = variableSections?.indexOf(item.value);
        if (selectedIndex === -1) {
            setValue('outletSelection', [...variableSections, item.value]);
        } else {
            const newSelectedItems = [...variableSections];
            newSelectedItems.splice(selectedIndex, 1);
            setValue('outletSelection', newSelectedItems);
        }
        clearErrors('outletSelection');
    };

    const handleClickItemSubSection = (item) => {
        const selectedIndex = variableForSubSection?.indexOf(item.value);
        if (selectedIndex === -1) {
            setValue('subSection', [...variableForSubSection, item.value]);
        } else {
            const newSelectedItems = [...variableForSubSection];
            newSelectedItems.splice(selectedIndex, 1);
            setValue('subSection', newSelectedItems);
        }
        clearErrors('subSection');
    };
    const handleClickItemWaiters = (item) => {
        const selectedIndex = variableWaiters?.indexOf(item.value);
        if (selectedIndex === -1) {
            setValue('TablesWaiter', [...variableWaiters, item.value]);
        } else {
            const newSelectedItems = [...variableWaiters];
            newSelectedItems.splice(selectedIndex, 1);
            setValue('TablesWaiter', newSelectedItems);
        }
        clearErrors('TablesWaiter');
    };

    const data = {
        name: watch('Name'),
        email: watch('Email'),
        phone_detail_id: watch('mobile_number_id'),
        country_code: countryData?.code,
        table_ids: Waiters,
    };
    const handleAddWaiter = async () => {
        try {
            const response = await APIV1.post(`restaurants/${selectedOutletId}/waiters`, data);
            setShowWaiterPage(false);
            {
                response.success === true && setriderSaved((prevState) => !prevState);
            }
        } catch (error) {
            console.log('error ==>', error);
        }
    };

    const handleDiscard = () => {
        setShowWaiterPage(false);
    };
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    useEffect(() => {
        const fetchSectionNames = async () => {
            try {
                const response = await APIV5.get(`outlets/${selectedOutletId}/outlet-sections/section-list`);
                if (response.sections) {
                    const Selection = response.sections.map((section) => ({
                        label: section.internal_name,
                        value: section.id,
                        sub_section: section?.sub_section,
                    }));

                    setSections(Selection);
                }
            } catch (error) {
                console.error('Error fetching section names:', error);
            }
        };

        fetchSectionNames();
    }, [selectedOutletId]);
    const [waitersTablesdata, SetwaitersTablesData] = useState([]);
    const detailsOfTheTables = waitersTablesdata.map((item) => ({
        label: item?.internal_name,
        value: item?.id,
    }));
    const [ previousSubSection, setPreviousSubSection ] = useState([]);
    const isSubSectionChanged = () => {
        if (subSection.length !== previousSubSection.length) return true;

        for (let i = 0; i < subSection.length; i++) {
            if (subSection[i] !== previousSubSection[i]) return true;
        }

        return false;
    };

    const fetchTableDataForId = async (id) => {
        try {
            let currentPage = 1;

            while (true) {
                const response = await APIV5.get(`/outlets/${selectedOutletId}/outlet-sections/${id}/table-list`, {
                    params: {
                        per_page: 10,
                        page: currentPage,
                    },
                });
                if (!response.success || !response?.tables?.data) {
                    break;
                }
                SetwaitersTablesData([...waitersTablesdata, ...response?.tables?.data]);
                if (currentPage >= response.tables.last_page) {
                    break;
                }

                currentPage++;
            }
        } catch (error) {
            console.error(`Error fetching data for ID ${id}:`, error);
            return null;
        }
    };
    const fetchAllTableData = async () => {
        try {
            const promises = SubSectionFilter.map((id) => fetchTableDataForId(id.value));
            const results = await Promise.all(promises);
        } catch (error) {
            console.error('Error fetching all table data:', error);
            return [];
        }
    };
    useEffect(() => {
        if (subSection.length > 0) {
        }
    }, [subSection, selectedOutletId]);
    const onAddSubmit = () => {
        if (!getValues('outletSelection')?.length) {
            setError('outletSelection', { message: 'Please select at least one outlet' });
        } else {
            clearErrors('outletSelection');
        }

        handleSubmit(handleAddWaiter)();
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 md:z-[9] flex overflow-auto px-[33px] py-4 md:bg-white md:relative md:py-0 md:px-1'>
                <div
                    className='max-w-[830px] min-h-[388px] rounded-xl bg-shades-50 px-8 py-6 m-auto w-full md:max-w-full md:px-0 md:py-0 md:min-h-full'
                    ref={popupRef}>
                    {isLoadingEditRider ? (
                        <WaiterPopupLoader />
                    ) : (
                        <div ref={popupRef}>
                            {isMobileScreen && (
                                <div
                                    className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                                    onClick={() => handleClickAddRider()}>
                                    <LeftArrow className='rotate-90' />
                                    <span className='ml-1'>Back to Waiter</span>
                                </div>
                            )}

                            <div className='flex flex-row justify-between items-center border-neutral-300'>
                                <div>
                                    {addOrEdit === 'add' ? (
                                        <>
                                            <span className='paragraph-large-medium'>Add Waiter</span>
                                            <div className='flex flex-row items-center'>
                                                <span className='paragraph-medium-italic text-neutral-500'>
                                                    Register a new waiter for your outlet
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        addOrEdit === 'edit' && (
                                            <>
                                                <span className='paragraph-large-medium'>Edit rider</span>
                                                <div className='flex flex-row items-center'>
                                                    <span className='paragraph-medium-italic text-neutral-500'>
                                                        Edit an existing rider for your outlet
                                                    </span>
                                                </div>
                                            </>
                                        )
                                    )}
                                </div>
                                <div
                                    onClick={setShowWaiterPage}
                                    className='md:hidden cursor-pointer'>
                                    <Close />
                                </div>
                            </div>

                            <div>
                                {addRiderCardInputField.map((el, index) => {
                                    return (
                                        <>
                                            <div
                                                key={index}
                                                className='align-top inline-block w-1/2 even:pl-2 odd:pr-2 my-2'>
                                                {el.label === 'Mobile number' && (
                                                    <div className='w-full  relative lg:w-full lg:ml-0 remove-spin-button'>
                                                        <Controller
                                                            render={() => (
                                                                <InputField
                                                                    inputValue={validatedNumber ?? ''}
                                                                    setValue={setValue}
                                                                    showMobileIcon={false}
                                                                    isMobileNumberPage={true}
                                                                    isValidInput={isValidInput}
                                                                    setIsValidInput={setIsValidInput}
                                                                    onInputClick={() => {
                                                                        setIsShowMobileNumberPopup(true);
                                                                    }}
                                                                    paddingLeft='pl-[105px]'
                                                                    mobileNumberFIeldName={'mobileNumber'}
                                                                    otpFieldName={'otp'}
                                                                />
                                                            )}
                                                            name={`validatedNumber`}
                                                            control={control}
                                                            rules={{ required: 'Mobile number is required' }}
                                                        />
                                                        <div
                                                            className={`phoneinputbox absolute ${
                                                                errors?.validatedNumber ? 'bottom-8' : 'bottom-3'
                                                            } left-4`}
                                                            onClick={() => setIsShowMobileNumberPopup(true)}>
                                                            <PhoneInputDropdown
                                                                value={countryData?.code}
                                                                disabled={true}
                                                                countryData={countryData}
                                                                setCountryData={setCountryData}
                                                            />
                                                        </div>
                                                        {errors?.validatedNumber && (
                                                            <ErrorMessage errors={errors?.validatedNumber} />
                                                        )}

                                                        {isShowMobileNumberPopup && (
                                                            <ValidateUserPhoneNumber
                                                                setIsShowMobileNumberPopup={setIsShowMobileNumberPopup}
                                                                mobileNumber={mobileNumber}
                                                                otp={otp}
                                                                isValidInput={isValidInput}
                                                                setIsValidInput={setIsValidInput}
                                                                countryData={countryData}
                                                                setCountryData={setCountryData}
                                                                methods={{
                                                                    setValue,
                                                                    setError,
                                                                    formState: { errors },
                                                                    clearErrors,
                                                                    getValues,
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                                {el.label === "Select Waiter's Section" && (
                                                    <>
                                                        <DropdonwnDifferentFirstMenuItem
                                                            label="Select Waiter's Section"
                                                            menuItems={sections}
                                                            placeholder="Select Waiter's Section"
                                                            searchItemPlaceholder='Search outlet by typing...'
                                                            type='searchDropdown'
                                                            openFromBottom={isMobileScreen ?? true}
                                                            handleClickItem={handleClickItem}
                                                            watch={watch}
                                                            name='outletSelection'
                                                        />
                                                        {errors?.outletSelection && (
                                                            <ErrorMessage errors={errors?.outletSelection} />
                                                        )}
                                                    </>
                                                )}
                                                {el.label === "Select Waiter's Sub Section" && (
                                                    <>
                                                        <DropdonwnDifferentFirstMenuItem
                                                            label='Select Sub Section'
                                                            menuItems={SubSectionFilter}
                                                            placeholder='Select Sub Section'
                                                            searchItemPlaceholder='Search outlet by typing...'
                                                            type='searchDropdown'
                                                            openFromBottom={isMobileScreen ?? true}
                                                            handleClickItem={handleClickItemSubSection}
                                                            watch={watch}
                                                            name='subSection'
                                                        />
                                                        {errors?.outletSelection && (
                                                            <ErrorMessage errors={errors?.outletSelection} />
                                                        )}
                                                    </>
                                                )}
                                                {el.label === "Select Waiter's Tables" && (
                                                    <div
                                                        onClick={() => {
                                                            if (subSection.length > 0 && isSubSectionChanged()) {
                                                                fetchAllTableData();
                                                                setPreviousSubSection([...subSection]); 
                                                            }
                                                        }}>
                                                        <DropdonwnDifferentFirstMenuItem
                                                            label="Select Waiter's Tables"
                                                            menuItems={detailsOfTheTables}
                                                            placeholder="Select Waiter's Tables"
                                                            searchItemPlaceholder={`Search Waiter's Table by typing...`}
                                                            type='searchDropdown'
                                                            openFromBottom={isMobileScreen ?? true}
                                                            handleClickItem={handleClickItemWaiters}
                                                            watch={watch}
                                                            name='TablesWaiter'
                                                        />
                                                        {errors?.outletSelection && (
                                                            <ErrorMessage errors={errors?.outletSelection} />
                                                        )}
                                                    </div>
                                                )}
                                                {el.label !== "Select Waiter's Sub Section" &&
                                                    el.label !== 'Mobile number' &&
                                                    el.label !== "Select Waiter's Section" &&
                                                    el.label !== "Select Waiter's Tables" && (
                                                        <Controller
                                                            key={index}
                                                            render={({ field }) => (
                                                                <div>
                                                                    <DefaultInputField
                                                                        label={el.label}
                                                                        placeholder={el.placeholder}
                                                                        enteredValue={field.onChange}
                                                                        value={field.value}
                                                                        onChange={() => {
                                                                            // field.onChange();
                                                                            trigger(el.label);
                                                                        }}
                                                                    />
                                                                    {!field.value && errors && errors[el.label] && (
                                                                        <ErrorMessage errors={errors[el.label]} />
                                                                    )}
                                                                </div>
                                                            )}
                                                            name={el.label}
                                                            control={control}
                                                            rules={{
                                                                required: el.required && el.label + ' is required',
                                                            }}
                                                        />
                                                    )}
                                            </div>
                                        </>
                                    );
                                })}
                            </div>

                            {addOrEdit === 'add' ? (
                                <div className='flex flex-row items-center md:block md:pb-20 mt-14'>
                                    <div className='w-1/2 mr-2 md:w-full'>
                                        <span className='paragraph-large-medium'>Step 1/1</span>
                                    </div>
                                    <div
                                        className='w-1/2 ml-2 md:hidden'
                                        onClick={onAddSubmit}>
                                        <LargePrimaryButton label='Add Waiter' />
                                    </div>
                                </div>
                            ) : (
                                addOrEdit === 'edit' && (
                                    <div className='flex justify-between'>
                                        <div
                                            className='w-8/12 pr-2'
                                            onClick={onAddSubmit}>
                                            <LargePrimaryButton
                                                label='Edit rider'
                                                type='submit'
                                            />
                                        </div>
                                        <div
                                            className='w-4/12 pl-2'
                                            onClick={handleDiscard}>
                                            <LargeDestructiveButton
                                                label='Discard Rider'
                                                type='submit'
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
